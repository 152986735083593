import {
  Button,
  ConfirmationDialog,
  Icon,
  SecretValue,
  VariablesViewAddEdit,
  VariablesViewAddEditProps,
  WalTable,
  WalTableColumn,
} from '@humanitec/ui-components';
import { unset } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import AppValuesLink from '@src/components/shared/AppValuesLink';
import SharedAppValuesHistory from '@src/components/shared/SharedAppValuesHistory/SharedAppValuesHistory';
import { getOutdatedValues } from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/SharedAppValues/shared-app-values-utils';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import useEnvValuesCreateMutation from '@src/hooks/react-query/shared-values/mutations/useEnvValuesCreateMutation';
import useEnvValuesDeleteMutation from '@src/hooks/react-query/shared-values/mutations/useEnvValuesDeleteMutation';
import useEnvValuesUpdateMutation from '@src/hooks/react-query/shared-values/mutations/useEnvValuesUpdateMutation';
import useAppValuesQuery from '@src/hooks/react-query/shared-values/queries/useAppValuesQuery';
import useEnvValuesQuery from '@src/hooks/react-query/shared-values/queries/useEnvValuesQuery';
import useSharedAppValuesHistoryQuery from '@src/hooks/react-query/shared-values/queries/useSharedAppValuesHistoryQuery';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { AppValue } from '@src/models/app-value';
import { units } from '@src/styles/variables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: ${units.margin.sm};
`;

const StyledDash = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const ResetButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const EnvValues = () => {
  const [expandedRow, setExpandedRow] = useState<number | undefined>();
  const [rowToOverrideColumns, setRowToOverrideColumns] = useState<number | undefined>();
  const [hideResetConfirmationDialog, setHideResetConfirmationDialog] = useState<boolean>(true);
  const [resetConfirmationDialogIndexToShow, setResetConfirmationDialogIndexToShow] = useState<
    string | undefined
  >();

  // Env versions local state
  const [showEnvVersions, setShowEnvVersions] = useState(false);
  const [selectedKeyValue, setSelectedKeyValue] = useState<string | null>(null);

  // i18n
  const { t } = useTranslation();
  const configsTranslations = t('VIEW_MODULE').CONFIGS;
  const envSettingsTranslations = t('ENVIRONMENT_SETTINGS');
  const uiTranslations = t('UI');

  // Context
  const { orgRole, appRole } = useGetUserRoles();

  // react query
  const { data: appValues = [] } = useAppValuesQuery();
  const { data: envValues = [] } = useEnvValuesQuery();
  const { responseData: envValueSets = [] } = useSharedAppValuesHistoryQuery(true);
  const { data: environment } = useEnvironmentQuery();
  const { mutate: createEnvValue } = useEnvValuesCreateMutation();
  const { mutate: updateEnvValue } = useEnvValuesUpdateMutation();
  const { mutate: deleteEnvValue } = useEnvValuesDeleteMutation();

  const outdatedValues = useMemo(
    () => environment && getOutdatedValues(environment, envValueSets),
    [environment, envValueSets]
  );

  // RBAC
  // This restricts editing/deleting permissions based on RBAC detailed at https://www.notion.so/humanitec/Frontend-Engineering-f8a268f74f7e4a48a3f5f8eea4d94040#6ef33f52f696404593bb7d54c1a2b5cc
  const canEdit = orgRole === 'administrator' || appRole === 'developer' || appRole === 'owner';

  // Methods
  const handleEdit = (index: number) => {
    setExpandedRow(index);
    setRowToOverrideColumns(index);
  };

  const handleCancelEditMode = () => {
    setRowToOverrideColumns(undefined);
  };

  const handleResetConfirmationAction = (envValueKey: string) => {
    deleteEnvValue({ envValueKey });
  };

  const overrideValue = (updatedValue: AppValue, oldEnvValue: AppValue) => {
    const payload = {
      value: updatedValue?.value,
      description: updatedValue.description,
      is_secret: updatedValue.is_secret,
      key: updatedValue.key,
    };

    if (updatedValue?.value !== '' || payload.description !== '') {
      if (oldEnvValue.source === 'app') {
        createEnvValue({ envValue: payload });
      } else if (oldEnvValue.source === 'env') {
        unset(payload, 'is_secret');
        updateEnvValue({ envValue: payload });
      }
    }
  };

  const getKeyValue = (appValue: AppValue) =>
    envValues.find((e) => e.key === appValue.key) || appValue;

  const columns: WalTableColumn<AppValue & { path: string }>[] = [
    {
      label: uiTranslations.KEY_LABEL,
      prop: 'key',
      ellipsisTooltip: {
        maxWidth: 150,
        maxCharacters: 20,
        text: (row) => row.data.key.replace(/([_.-])/g, '$1\u200b'),
      },
    },
    {
      label: uiTranslations.VALUE_LABEL,
      prop: 'value',
      ellipsisTooltip: {
        maxWidth: 150,
        maxCharacters: 20,
        text: (row) =>
          row.data.is_secret && row.data.secret_version
            ? envSettingsTranslations.SECRET_ID + ': ' + row.data.secret_version
            : row.data.value,
      },
      template: (row) =>
        row.data.is_secret ? (
          <SecretValue secretVersionId={row.data.secret_version} />
        ) : (
          <p>{row.data?.value || <StyledDash>-</StyledDash>}</p>
        ),
    },
    {
      label: uiTranslations.DESCRIPTION,
      prop: 'description',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 30,
        text: (row) => row.data.description,
      },
      template: (row) => <p>{row.data?.description || <StyledDash>-</StyledDash>}</p>,
    },
    {
      label: configsTranslations.HAS_OVERRIDES,
      prop: 'has_overrides',
      template: (row) => (
        <p>{row.data.source === 'env' ? <Icon name={'checkmark'} /> : <span />}</p>
      ),
    },
    {
      prop: 'warning',
      fixedWidth: 180,
      template: (row) =>
        outdatedValues?.includes(row.data.key) && (
          <>
            <Icon name={'info'} className={'mr-md'} />
            {envSettingsTranslations.NOT_DEPLOYED}
          </>
        ),
    },
    {
      prop: 'actions',
      tabIndex: -1,
      fixedWidth: 130,
      template: (row, index) => (
        <ActionsWrapper>
          {canEdit && (
            <>
              <ResetButtonWrapper>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setResetConfirmationDialogIndexToShow(row.data.key);
                    setHideResetConfirmationDialog(false);
                  }}
                  variant={'secondary'}
                  size={'small'}
                  ariaLabel={`${envSettingsTranslations.REMOVE_OVERRIDE} ${row?.data?.key}`}
                  disabled={row.data.source !== 'env'}>
                  {uiTranslations.RESET}{' '}
                </Button>
                {!hideResetConfirmationDialog &&
                  resetConfirmationDialogIndexToShow === row.data.key && (
                    <ConfirmationDialog
                      key={row.data.key}
                      onConfirm={() => handleResetConfirmationAction(row.data.key)}
                      hiddenState={[hideResetConfirmationDialog, setHideResetConfirmationDialog]}
                      confirmationMessage={envSettingsTranslations.RESET_CONFIRMATION_TEXT}
                      confirmationButtonText={uiTranslations.RESET}
                    />
                  )}
              </ResetButtonWrapper>
              <Button
                iconLeft={'edit'}
                variant={'secondary'}
                size={'small'}
                ariaLabel={uiTranslations.EDIT}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(index);
                }}
              />
            </>
          )}
          {/** Show history button to all users */}
          <Button
            key={'history'}
            iconLeft={'history'}
            variant={'secondary'}
            size={'small'}
            ariaLabel={uiTranslations.SHOW_HISTORY}
            onClick={(e) => {
              setShowEnvVersions(true);
              setSelectedKeyValue(row.data.key);
              e.stopPropagation(); // do not show expanded content on clicking
            }}
          />
        </ActionsWrapper>
      ),
    },
  ];

  const renderVariablesViewAddEdit = ({
    id,
    keyValue,
    onSave,
    onCancel,
    mode,
    isSaving,
    disableDescriptionField,
    isValueFieldRequired,
  }: VariablesViewAddEditProps) => (
    <VariablesViewAddEdit
      key={keyValue?.key}
      id={id}
      keyValue={keyValue}
      onSave={onSave}
      onCancel={onCancel}
      mode={mode}
      isSaving={isSaving}
      disableDescriptionField={disableDescriptionField}
      disableKeyField
      showDescription
      isValueFieldRequired={isValueFieldRequired}
    />
  );

  return (
    <>
      <Wrapper>
        <AppValuesLink />
        <WalTable
          caption={envSettingsTranslations.OVERRIDES}
          columns={columns}
          expandedRowState={[expandedRow, setExpandedRow]}
          disableScrolling
          tableStyle={'expandable'}
          rows={
            appValues
              ? appValues?.map(getKeyValue).map((appValue, rowIndex) => ({
                  data: appValue,
                  expandableContent: renderVariablesViewAddEdit({
                    keyValue: getKeyValue(appValue),
                    mode: 'view',
                  }),
                  overrideColumns: rowToOverrideColumns === rowIndex,
                  columnOverrideTemplate: renderVariablesViewAddEdit({
                    keyValue: getKeyValue(appValue),
                    mode: 'edit',
                    onSave: (newValues) => overrideValue(newValues, getKeyValue(appValue)),
                    onCancel: handleCancelEditMode,
                    disableKeyField: true,
                    isValueFieldRequired: appValue.is_secret && appValue.source === 'app',
                  }),
                }))
              : []
          }
        />
      </Wrapper>
      {showEnvVersions && selectedKeyValue !== null && (
        <SharedAppValuesHistory
          keyValue={selectedKeyValue}
          onClose={() => setShowEnvVersions(false)}
          isEnvironmentOverride
        />
      )}
    </>
  );
};

export default EnvValues;
