import { Button, Radio, WalCard } from '@humanitec/ui-components';
import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import WorkloadOrResourceCardTitle from '@src/components/shared/CloneModal/components/ReviewCloneDetails/components/WorkloadOrResourceCardTitle';
import { TitleRow } from '@src/components/shared/CloneModal/components/ReviewCloneDetails/ReviewCloneDetails';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentObject } from '@src/models/deployment-object';
import { DeploymentSet } from '@src/models/deployment-set';
import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';

interface UpdateWorkloadsAccordionContentProps {
  defaultCloneDraftContent: Partial<DeploymentDelta>;
  defaultCloneDraftWithGroupedResourceUpdates: Partial<DeploymentDelta>;
  cloneDraftContentState: [
    Partial<DeploymentDelta>,
    Dispatch<SetStateAction<Partial<DeploymentDelta>>>,
  ];
  targetEnv?: Environment;
  fromDeployment?: DeploymentObject;
  targetDeploymentSet?: DeploymentSet;
  addResourceChangeInCloneDraft: (id: string) => void;
  removeResourceChangeFromCloneDraft: (id: string) => void;
}
const UpdateWorkloadsAccordionContent = ({
  targetEnv,
  defaultCloneDraftContent,
  defaultCloneDraftWithGroupedResourceUpdates,
  cloneDraftContentState,
  fromDeployment,
  targetDeploymentSet,
  addResourceChangeInCloneDraft,
  removeResourceChangeFromCloneDraft,
}: UpdateWorkloadsAccordionContentProps) => {
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const modalTranslations = tViewEnvironment('DEPLOYS').DEPLOYMENT_CARD.CLONE_MODAL;
  const { appId, orgId } = useParams<keyof MatchParams>() as MatchParams;
  const [cloneDraftContent, setCloneDraftContent] = cloneDraftContentState;

  const updateWorkloadInCloneDraft = (id: string) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent.modules?.update && defaultCloneDraftContent?.modules?.update) {
      newCloneDraftContent.modules.update[id] = defaultCloneDraftContent?.modules?.update[id];
    }
    setCloneDraftContent(newCloneDraftContent);
  };

  const dontUpdateWorkloadInCloneDraft = (id: string) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent.modules?.update) {
      delete newCloneDraftContent.modules?.update[id];
    }
    setCloneDraftContent(newCloneDraftContent);
  };

  const setAllUpdateWorkloads = (updateAll?: boolean) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent.modules) {
      newCloneDraftContent.modules.update = updateAll
        ? cloneDeep(defaultCloneDraftContent.modules?.update)
        : {};
      newCloneDraftContent.shared = updateAll
        ? defaultCloneDraftContent.shared && [...defaultCloneDraftContent.shared]
        : defaultCloneDraftWithGroupedResourceUpdates.shared?.filter(
            (change) => change.op !== 'replace'
          );
    }
    setCloneDraftContent(newCloneDraftContent);
  };

  const renderUpdateWorkloadCard = (id: string, isWorkload?: boolean, subtitle?: string) => (
    <WalCard className={'mb-sm'} cardStyle={'base'} key={id}>
      <TitleRow>
        {fromDeployment?.env_id && (
          <WorkloadOrResourceCardTitle
            orgId={orgId}
            appId={appId}
            envId={fromDeployment?.env_id}
            deploymentId={fromDeployment?.id}
            id={id}
            subtitle={subtitle}
          />
        )}
        <div className={'flex-centered'}>
          <Radio
            name={`update-${id}`}
            label={`${modalTranslations.UPDATE_IN} ${targetEnv?.id}`}
            value={`update-${id}`}
            className={'mr-md'}
            defaultChecked={
              isWorkload
                ? Boolean(
                    cloneDraftContent.modules?.update &&
                      Object.keys(cloneDraftContent.modules?.update).includes(id)
                  )
                : Boolean(
                    cloneDraftContent.shared?.find((resourceChange) =>
                      resourceChange.path.includes(`/${id}`)
                    )
                  )
            }
            onClick={() =>
              isWorkload ? updateWorkloadInCloneDraft(id) : addResourceChangeInCloneDraft(`/${id}`)
            }
          />
          <Radio
            name={`update-${id}`}
            label={`${modalTranslations.DONT_UPDATE_IN} ${targetEnv?.id}`}
            value={`dont-update-${id}`}
            defaultChecked={
              isWorkload
                ? Boolean(
                    cloneDraftContent.modules?.update &&
                      !Object.keys(cloneDraftContent.modules?.update).includes(id)
                  )
                : !Boolean(
                    cloneDraftContent.shared?.find((resourceChange) =>
                      resourceChange.path.includes(id)
                    )
                  )
            }
            onClick={() =>
              isWorkload
                ? dontUpdateWorkloadInCloneDraft(id)
                : removeResourceChangeFromCloneDraft(id)
            }
          />
        </div>
      </TitleRow>
    </WalCard>
  );

  return (
    <div>
      <p className={'txt-sm txt-translucent mb-md'}>
        {modalTranslations.UPDATE_WORKLOADS_SUBHEADING1}
      </p>
      <p className={'txt-sm txt-translucent mb-md'}>
        <Trans
          defaults={modalTranslations.UPDATE_WORKLOADS_SUBHEADING2}
          values={{ targetEnv: targetEnv?.id, sourceEnv: fromDeployment?.env_id }}>
          <span />
        </Trans>
      </p>
      <p className={'txt-sm txt-translucent mb-md'}>
        <span className={'mr-md'}>{modalTranslations.SET_ALL_TO}</span>
        <Button variant={'borderless-blue'} onClick={() => setAllUpdateWorkloads(true)}>
          {modalTranslations.UPDATE}
        </Button>
        <Button variant={'borderless-blue'} onClick={() => setAllUpdateWorkloads()}>
          {modalTranslations.DONT_UPDATE}
        </Button>
      </p>
      {defaultCloneDraftContent.modules?.update &&
        Object.keys(defaultCloneDraftContent.modules?.update)?.map((workloadId) =>
          renderUpdateWorkloadCard(
            workloadId,
            true,
            targetDeploymentSet?.modules[workloadId]?.profile
          )
        )}
      {defaultCloneDraftWithGroupedResourceUpdates.shared
        ?.filter((resourceChange) => resourceChange.op === 'replace')
        .map((resourceChange) =>
          renderUpdateWorkloadCard(
            resourceChange.path.slice(1),
            false,
            targetDeploymentSet?.shared?.[resourceChange.path.slice(1)]?.type
          )
        )}
    </div>
  );
};

export default UpdateWorkloadsAccordionContent;
