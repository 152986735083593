import { WarningSection } from '@humanitec/ui-components';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Route, Routes, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentErrorsQuery from '@src/hooks/react-query/environments/queries/useDeploymentErrorsQuery';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import useShowScoreWarning from '@src/hooks/useShowScoreWarning';
import { Workload } from '@src/models/deployment-set';
import { MatchParams } from '@src/models/routing';
import { WorkloadProfileVersion } from '@src/models/workload-profile';

import DisplayDeploymentErrors from '../DisplayDeploymentErrors';
import RenderFeature from './components/RenderFeature/RenderFeature';
import { findSchemaRoutingPaths } from './utils/utils';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

const Wrapper = styled.div<{ readonly: boolean }>`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

interface WorkloadProfileProps {
  schema: WorkloadProfileVersion['spec_definition'];
}

/**
 * Renders the workload screen based on a given workload profile schema.
 */
const ViewWorkloadProfile = ({ schema }: WorkloadProfileProps) => {
  const { showScoreWarning, managedByLink, managedBy } = useShowScoreWarning();

  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;

  // Delta utils
  const { data: workload } = useDeltaUtils<Workload>('');

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const collections = useMemo(() => findSchemaRoutingPaths(schema, workload), [workload, schema]);

  // Router hooks
  const { deployId, moduleId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: deploymentErrors } = useDeploymentErrorsQuery(deployId);

  return (
    <Wrapper readonly={!draftModeActive}>
      <Container>
        {showScoreWarning && (
          <>
            <WarningSection showIcon>
              <span>
                {sectionsTranslations.SCORE_WARNING}{' '}
                {managedByLink !== null ? (
                  <a href={managedByLink} target={'_blank'} rel={'noreferrer'}>
                    {managedBy}
                  </a>
                ) : (
                  managedBy
                )}
              </span>
              <span className={'txt-translucent'}>{sectionsTranslations.SCORE_WARNING2}</span>
            </WarningSection>
          </>
        )}
        <div className={'txt-sm mb-sm'}>
          <Trans
            key={'VIEW_MODULE.SECTIONS.BASED_ON_PROFILE'}
            defaults={sectionsTranslations.BASED_ON_PROFILE}
            values={{ workloadProfileId: workload?.profile.split('/')[1] }}
            components={{ bold: <b /> }}
          />
        </div>
        {deploymentErrors && deploymentErrors?.length > 0 && (
          <DisplayDeploymentErrors
            errors={deploymentErrors}
            scope={'workload'}
            object_id={moduleId}
          />
        )}
        <Routes>
          <Route
            path={'/*'}
            element={
              <RenderFeature
                features={schema?.properties || {}}
                runtimeProperties={schema?.runtime_properties || []}
                deltaPath={'spec'}
                showResourceDependencies
              />
            }
          />
          {Object.entries(collections.paths).map(([key, paths]) =>
            paths.map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <RenderFeature
                    features={collections.featureGroups[key].deltaProperties}
                    runtimeProperties={collections.featureGroups[key].runtimeProperties}
                    deltaPath={`spec/${path}`}
                  />
                }
              />
            ))
          )}
        </Routes>
      </Container>
    </Wrapper>
  );
};

export default ViewWorkloadProfile;
