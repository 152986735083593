import { WalCard } from '@humanitec/ui-components';
import { Handle, Position } from '@xyflow/react';
import styled from 'styled-components';

import ResourceCardHeaderContent from '@src/components/shared/ResourceDependencyList/components/ResourceCardHeaderContent/ResourceCardHeaderContent';
import { ActiveResource } from '@src/models/resources';

interface ResourceNodeProps {
  data: ActiveResource & {
    selected: boolean;
    provisionTime: string;
  };
}

const CustomCard = styled(WalCard)`
  .react-flow__handle {
    opacity: 0;
    pointer-events: none;
  }
`;
const ResourceNode = ({ data }: ResourceNodeProps) => {
  return (
    <CustomCard
      id={data.res_id}
      dataTestId={data.res_id}
      highlightOnHover
      selected={data.selected}
      className={'nodrag'}>
      <Handle type={'target'} position={Position.Top} />
      <ResourceCardHeaderContent
        draftModeActive={false}
        scope={'private'}
        resourceId={data.res_id}
        resourceClass={data.class}
        resourceType={data.type}
        iconSize={20}
      />
      <Handle type={'source'} position={Position.Bottom} />
    </CustomCard>
  );
};

export default ResourceNode;
