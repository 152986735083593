import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { rem } from 'polished';
import { Dispatch, MouseEvent, ReactNode, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { viewAppBreakpoints } from '@src/styles/breakpoints';
import { units } from '@src/styles/variables';

import { Button, ButtonProps } from '../Button/Button';
import { CloseIcon } from '../CloseIcon';
import InfoPopup, { InfoPopupProps } from '../InfoPopup/InfoPopup';

/**
 * Props for modal component.
 */
export interface ModalProps {
  /** useState from the parent to check the if the modal opened state */
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  /** size of the modal */
  size?: 'medium' | 'large' | 'xlarge';
  /** custom size of the modal */
  customSize?: number;
  /** Title */
  title?: string | ReactNode;
  titleInfoPopUp?: InfoPopupProps;
  /** Subtitle */
  subTitle?: string | ReactNode;
  /** Content */
  content?: ReactNode;
  /** Actions Section */
  actions?: {
    cancel?: {
      /** text of the button, if this is not provided it will fall back to the default text "Cancel" */
      text?: string;
      hideButton?: boolean;
      /** properties of the button component */
      props?: Omit<ButtonProps, 'variant' | 'size' | 'type'>;
    };
    main?: {
      /** text of the button, if this is not provided it will fall back to the default text "Done" */
      text?: string;
      hideButton?: boolean;
      /** properties of the button component */
      props: Omit<ButtonProps, 'size'>;
    };
    /* set justify-content of the buttons wrapper to 'space-between'*/
    justifyContentSpaceBetween?: boolean;
    /* This should be used only in case if the standard 2 buttons layout doesn't work e.g. MultiStepModal */
    customComponent?: ReactNode;
  };
  /** Optional className */
  className?: string;
  disableOverflow?: boolean;
  id?: string;
  disableClickOutside?: boolean;
  dataTestId?: string;
  backgroundColor?: 'base' | 'baseBrighter' | 'baseLayer' | 'baseDarker';
  onModalClick?: (e: MouseEvent) => void;
  /**
   * If we need to show the cross on top right to close modal
   */
  showClose?: boolean;
  children?: ReactNode;
  /* If you pass this function, then a form elenent with submit handler will be rendered around the modal content & actions */
  handleFormSubmit?: (formValues: any) => void;
  /**
   * FloatingFocusManager automatically focuses the first tabbable item in the modal.
   * Passing this prop disables the automatic focus by setting the focus to the floating element i.e. the modal element.
   *
   * Floating UI docs: https://floating-ui.com/docs/floatingfocusmanager#initialfocus
   */
  disableInitialFocus?: boolean;
}

const ModalWrapper = styled(FloatingOverlay)`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99; /* Sit on top */
  background-color: rgba(0, 0, 0, 0.4);
`;

type ModalContentProps = Pick<
  ModalProps,
  'backgroundColor' | 'content' | 'disableOverflow' | 'customSize' | 'size'
>;
const ModalContent = styled.div<ModalContentProps>`
  /**
   * ----------------------------------------
   * animation scale-up-center
   * ----------------------------------------
   */
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  max-height: 80vh;
  position: relative;
  @media ${viewAppBreakpoints.smallerThanIncluding.sm} {
    margin: auto 5%;
  }
  padding: ${units.padding.xxl};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: ${({ theme, backgroundColor }) =>
    theme.color[backgroundColor || 'baseBrighter']};
  animation: scale-up-center 0.1s ease-in;
  min-width: ${rem(350)};

  ${({ content, disableOverflow }) =>
    !content &&
    !disableOverflow &&
    css`
      overflow-y: auto;
    `}

  width: ${({ size, customSize }) =>
    customSize
      ? rem(customSize)
      : size === 'large'
        ? units.modal.lg
        : size === 'xlarge'
          ? units.modal.xl
          : units.modal.md};
`;

/**
 * Defaut modal styles.
 */
export const ModalTitle = styled.h3`
  margin: ${units.margin.xs} 0 ${units.margin.sm};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Subtitle = styled.span`
  font-size: ${units.fontSize.base};
  margin-bottom: ${units.margin.md};
`;

const Content = styled.div<{ disableOverflow: boolean | undefined }>`
  /* To accomodate scrollbar */
  margin: 0 -${units.padding.xl};
  padding: 0 ${units.padding.xl};
  font-size: ${units.fontSize.sm};
  ${({ disableOverflow }) =>
    !disableOverflow &&
    css`
      overflow-y: auto;
    `}
`;

const Actions = styled.div<{ justifyContentSpaceBetween?: boolean }>`
  margin-top: ${units.margin.sm};
  display: flex;
  justify-content: flex-start;
  ${({ justifyContentSpaceBetween }) =>
    justifyContentSpaceBetween &&
    css`
      justify-content: space-between;
      width: 100%;
    `}
  > * {
    margin-right: ${units.margin.sm};
  }
`;

const ModalElement = ({
  children,
  title,
  titleInfoPopUp,
  subTitle,
  disableOverflow,
  content,
  actions,
  openState,
  showClose = false,
}: ModalProps) => {
  const [_, setModalOpen] = openState;
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  return (
    <>
      {children || (
        <>
          {showClose && (
            <CloseIcon ariaLabel={uiTranslations.ClOSE_MODAL} onClick={() => setModalOpen(false)} />
          )}
          {typeof title === 'string' ? (
            <TitleWrapper data-testid={'title-wrapper'}>
              <ModalTitle>{title}</ModalTitle>
              {titleInfoPopUp && <InfoPopup {...titleInfoPopUp} />}
            </TitleWrapper>
          ) : (
            title
          )}
          {subTitle && <Subtitle>{subTitle}</Subtitle>}
          <Content disableOverflow={disableOverflow}>{content}</Content>
          {actions && (
            <Actions justifyContentSpaceBetween={actions.justifyContentSpaceBetween}>
              {actions.main && !actions.main.hideButton && (
                <Button {...actions.main.props}>{actions.main.text || uiTranslations.DONE}</Button>
              )}
              {actions.cancel && !actions.cancel.hideButton && (
                <Button
                  {...actions.cancel.props}
                  variant={'secondary'}
                  onClick={actions.cancel.props?.onClick || (() => setModalOpen(false))}>
                  {actions.cancel.text || uiTranslations.CANCEL}
                </Button>
              )}
              {actions.customComponent && actions.customComponent}
            </Actions>
          )}
        </>
      )}
    </>
  );
};

const ModalWithForm = (props: ModalProps & Required<Pick<ModalProps, 'handleFormSubmit'>>) => {
  const { handleSubmit } = useFormContext();
  return (
    <form onSubmit={handleSubmit(props.handleFormSubmit)}>
      <ModalElement {...props} />
    </form>
  );
};

/**
 * Modal Component
 *
 * @param props Passed props.
 */
const WalModal = (props: ModalProps) => {
  const {
    openState,
    className,
    size,
    disableOverflow,
    id,
    disableClickOutside = false,
    dataTestId,
    backgroundColor,
    onModalClick,
    customSize,
    handleFormSubmit,
    title,
    disableInitialFocus,
  } = props;
  const [modalOpen, setModalOpen] = openState;

  const { refs, context } = useFloating({
    open: modalOpen,
    onOpenChange: setModalOpen,
  });

  const { getFloatingProps } = useInteractions([
    useRole(context, { role: 'dialog' }),
    useDismiss(context, {
      enabled: !disableClickOutside,
    }),
  ]);

  return (
    <FloatingPortal>
      {modalOpen && (
        <ModalWrapper onClick={onModalClick} data-testid={dataTestId} className={className}>
          <FloatingFocusManager
            context={context}
            initialFocus={disableInitialFocus ? refs.floating : undefined}>
            <ModalContent
              {...getFloatingProps({
                ref: refs.setFloating,
              })}
              aria-label={typeof title === 'string' ? title : undefined}
              size={size}
              backgroundColor={backgroundColor}
              disableOverflow={disableOverflow}
              customSize={customSize}
              className={'modal-content'}
              data-testid={'modal-content'}
              id={id}>
              {handleFormSubmit ? (
                <ModalWithForm {...props} handleFormSubmit={handleFormSubmit} />
              ) : (
                <ModalElement {...props} />
              )}
            </ModalContent>
          </FloatingFocusManager>
        </ModalWrapper>
      )}
    </FloatingPortal>
  );
};

export default WalModal;
