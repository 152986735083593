import { Button, Radio, WalCard } from '@humanitec/ui-components';
import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import WorkloadOrResourceCardTitle from '@src/components/shared/CloneModal/components/ReviewCloneDetails/components/WorkloadOrResourceCardTitle';
import { TitleRow } from '@src/components/shared/CloneModal/components/ReviewCloneDetails/ReviewCloneDetails';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentObject } from '@src/models/deployment-object';
import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';

interface AddWorkloadsAccordionContentProps {
  defaultCloneDraftContent: Partial<DeploymentDelta>;
  defaultCloneDraftWithGroupedResourceUpdates: Partial<DeploymentDelta>;
  cloneDraftContentState: [
    Partial<DeploymentDelta>,
    Dispatch<SetStateAction<Partial<DeploymentDelta>>>,
  ];
  addResourceChangeInCloneDraft: (id: string) => void;
  removeResourceChangeFromCloneDraft: (id: string) => void;
  targetEnv?: Environment;
  fromDeployment?: DeploymentObject;
}
const AddWorkloadsAccordionContent = ({
  targetEnv,
  defaultCloneDraftContent,
  defaultCloneDraftWithGroupedResourceUpdates,
  cloneDraftContentState,
  addResourceChangeInCloneDraft,
  removeResourceChangeFromCloneDraft,
  fromDeployment,
}: AddWorkloadsAccordionContentProps) => {
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const modalTranslations = tViewEnvironment('DEPLOYS').DEPLOYMENT_CARD.CLONE_MODAL;
  const { appId, orgId } = useParams<keyof MatchParams>() as MatchParams;
  const [cloneDraftContent, setCloneDraftContent] = cloneDraftContentState;

  const addWorkloadInCloneDraft = (id: string) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent.modules?.add && defaultCloneDraftContent?.modules?.add) {
      newCloneDraftContent.modules.add[id] = defaultCloneDraftContent?.modules.add[id];
    }
    setCloneDraftContent(newCloneDraftContent);
  };

  const dontAddWorkloadInCloneDraft = (id: string) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent.modules?.add) {
      delete newCloneDraftContent.modules?.add[id];
    }
    setCloneDraftContent(newCloneDraftContent);
  };

  const setAllAddWorkloadsAndResources = (addAll?: boolean) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent.modules) {
      newCloneDraftContent.modules.add = addAll
        ? cloneDeep(defaultCloneDraftContent.modules?.add)
        : {};
      newCloneDraftContent.shared = addAll
        ? defaultCloneDraftWithGroupedResourceUpdates.shared && [
            ...defaultCloneDraftWithGroupedResourceUpdates.shared,
          ]
        : defaultCloneDraftWithGroupedResourceUpdates.shared?.filter(
            (change) => change.op !== 'add'
          );
    }
    setCloneDraftContent(newCloneDraftContent);
  };
  const renderAddWorkloadOrSharedResourceCard = (
    id: string,
    isWorkload: boolean,
    subtitle?: string
  ) => (
    <WalCard className={'mb-sm'} cardStyle={'base'} key={id}>
      <TitleRow>
        {fromDeployment?.env_id && (
          <WorkloadOrResourceCardTitle
            orgId={orgId}
            appId={appId}
            envId={fromDeployment?.env_id}
            deploymentId={fromDeployment?.id}
            id={id}
            isWorkload={isWorkload}
            subtitle={subtitle}
          />
        )}
        <div className={'flex-centered'}>
          <Radio
            name={`add-${id}`}
            label={`${modalTranslations.ADD_TO} ${targetEnv?.id}`}
            value={`add-${id}`}
            className={'mr-md'}
            defaultChecked={
              isWorkload
                ? Boolean(
                    cloneDraftContent.modules?.add &&
                      Object.keys(cloneDraftContent.modules?.add).includes(id)
                  )
                : Boolean(
                    cloneDraftContent.shared?.find(
                      (resourceChange) => resourceChange.path === `/${id}`
                    )
                  )
            }
            onClick={() =>
              isWorkload ? addWorkloadInCloneDraft(id) : addResourceChangeInCloneDraft(`/${id}`)
            }
          />
          <Radio
            name={`add-${id}`}
            label={`${modalTranslations.DONT_ADD_TO} ${targetEnv?.id}`}
            value={`dont-add-${id}`}
            defaultChecked={
              isWorkload
                ? Boolean(
                    cloneDraftContent.modules?.add &&
                      !Object.keys(cloneDraftContent.modules?.add).includes(id)
                  )
                : !Boolean(
                    cloneDraftContent.shared?.find(
                      (resourceChange) => resourceChange.path === `/${id}`
                    )
                  )
            }
            onClick={() =>
              isWorkload
                ? dontAddWorkloadInCloneDraft(id)
                : removeResourceChangeFromCloneDraft(`/${id}`)
            }
          />
        </div>
      </TitleRow>
    </WalCard>
  );

  return (
    <div>
      <p className={'txt-sm txt-translucent mb-md'}>
        {modalTranslations.ADD_WORKLOADS_SUBHEADING1} {fromDeployment?.env_id}
      </p>
      <p className={'txt-sm txt-translucent mb-md'}>
        <Trans
          defaults={modalTranslations.ADD_WORKLOADS_SUBHEADING2}
          values={{ targetEnv: targetEnv?.id, sourceEnv: fromDeployment?.env_id }}>
          <span />
        </Trans>
      </p>
      <p className={'txt-sm txt-translucent mb-md'}>
        <span className={'mr-md'}>{modalTranslations.SET_ALL_TO}</span>
        <Button variant={'borderless-blue'} onClick={() => setAllAddWorkloadsAndResources(true)}>
          {modalTranslations.ADD}
        </Button>
        <Button variant={'borderless-blue'} onClick={() => setAllAddWorkloadsAndResources()}>
          {modalTranslations.DONT_ADD}
        </Button>
      </p>
      {defaultCloneDraftContent.modules?.add &&
        Object.entries(defaultCloneDraftContent.modules?.add)?.map(([workloadId, workload]) =>
          renderAddWorkloadOrSharedResourceCard(workloadId, true, workload?.profile)
        )}
      {defaultCloneDraftWithGroupedResourceUpdates.shared
        ?.filter((resourceChange) => resourceChange.op === 'add')
        .map((resourceChange) =>
          renderAddWorkloadOrSharedResourceCard(
            resourceChange.path.slice(1),
            false,
            resourceChange?.value?.type
          )
        )}
    </div>
  );
};

export default AddWorkloadsAccordionContent;
