import Editor, {
  BeforeMount,
  EditorProps as OriginalEditorProps,
  OnMount,
} from '@monaco-editor/react';
import { rem, remToPx, stripUnit } from 'polished';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { useMonacoStore } from '@src/hooks/zustand/useMonacoStore';
import { units } from '@src/styles/variables';
import { getUserPreferencesFromLS } from '@src/utilities/local-storage';

import { Spinner } from '../Spinner/Spinner';
import { FILES_EXTENSIONS } from './editor-constants';
import {
  addCustomLanguage,
  addThemes,
  getReactKeyboardEvent,
  insideAutocomplete,
  registerSuggestionCommand,
  setPlaceholderAutocomplete,
  setPlaceHolderSyntaxHighlighting,
  setTheme,
  validateYaml,
} from './editor-utils';
import EditorProps from './EditorProps';
import useEditorDisposers from './useEditorDisposers';

const TAB_FOCUS_MODE_OPTION = 132;

const StyledEditor = styled(Editor)<BasicEditorProps>`
  padding: ${({ inputSize }) =>
    inputSize && inputSize === 'large' ? units.padding.md : units.padding.xs};
  background: ${({ theme }) => theme.color.baseLayer};
  ${({ usedForInput }) =>
    usedForInput &&
    css`
      border-radius: ${rem(4)};
    `}

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `}
`;

export interface BasicEditorProps extends EditorProps {
  /** Pass id for monaco editor. Based on this value, the monaco modal is recognised and differentiated from other monacos*/
  id?: string;
  /**
   * Do something special to monaco editor when its used as input
   * 1. Change on keydown behaviour
   * 2. the way editor behaves when tab is pressed, blur and go to next element or insert tab character
   * 3. change the way editor looks, strip everything
   */
  usedForInput?: boolean;
  /** whether the input have multiple lines (like a textarea) */
  isMultiLineInput?: boolean;
  /** Show white space characters if true. Default is false*/
  renderWhitespace?: boolean;
  /** To disable/enabled the monaco context menu */
  monacoOptions?: OriginalEditorProps['options'];
}

/**
 * Basic monaco editor without other functionalities. The following features are NOT part of this component. Use `MonacoEditor` for them.
 * Features NOT present in this component:
 * - Language selector select dropbox
 * - Toggle for white space characters
 * - Displaying CR (Symbol for Carriage Return character)
 *
 * Features that are present:
 * + spcial behaviour while using for input
 * + YAML schema validation with schema validation
 * + Placeholders support
 */
export const BasicEditor = ({
  autofocus = false,
  className,
  customLanguage,
  dataTestid = 'basic-editor',
  fileExtension = 'text',
  fontSize,
  height,
  id,
  inputSize,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  onKeyUp,
  onValidate,
  placeholders,
  readOnly,
  renderWhitespace = false,
  tabIndex = 0,
  usedForInput = true,
  isMultiLineInput,
  value,
  width,
  yamlSchema,
  inputRef,
  monacoOptions = {},
  minHeight,
}: BasicEditorProps) => {
  const schemaRef = useRef(yamlSchema);
  /** Editor reference. Unfortunately there is no type for `editor`
   * editor is the editor object of monaco. While Monaco represents the component wrapped around editor.
   * Also editor is destroyed and created for each instance, but Monaco is retained
   */
  const editorRef = useRef<any>(null);
  const monacoRef = useRef<any>(null);
  const monacoRefCurrent = monacoRef?.current;

  /** set of disposers to cleanup event handlers. A custom hook to create and destroy them */
  const { setBlurDisposer, setFocusDisposer, setOnKeyDownHandlerDisposer, setOnKeyUpDisposer } =
    useEditorDisposers();
  const {
    variablesPlaceholders,
    setVariablesPlaceholders,
    filesPlaceholders,
    setFilesPlaceholders,
    resourcesPlaceholders,
    setResourcesPlaceholders,
  } = useMonacoStore();
  const [triggerSuggest, setTriggerSuggest] = useState(false);

  /** Constantly monitor theme changes*/
  const theme = getUserPreferencesFromLS()?.theme;

  /** */
  useEffect(() => {
    if (editorRef.current && theme) {
      setTheme(monacoRefCurrent, theme);
    }
  }, [theme, monacoRefCurrent]);

  /**
   * Focus automatically when the editor is loaded. Also consider if autofocus is set
   */
  useEffect(() => {
    if (!readOnly && editorRef?.current && autofocus) {
      editorRef.current.focus();
    }
  }, [editorRef, autofocus, readOnly]);

  useEffect(() => {
    /** If input is readonly, we dont need autocomplete*/
    if (!readOnly && monacoRefCurrent) {
      /** add autocomplete if placeholders are passed in.*/
      if (customLanguage === 'variables' && !variablesPlaceholders && placeholders) {
        setPlaceholderAutocomplete(monacoRefCurrent, JSON.parse(placeholders), ['variables']);
        setVariablesPlaceholders(true);
      } else if (
        (FILES_EXTENSIONS.includes(fileExtension) || customLanguage === 'files') && // when valid language is selected
        !filesPlaceholders && // if its not set already
        placeholders // if placeholders are actually passed
      ) {
        setPlaceholderAutocomplete(monacoRefCurrent, JSON.parse(placeholders), FILES_EXTENSIONS); // set autocomplete for all languages
        setFilesPlaceholders(true); // set this to true so that we dont repeat adding autocomplete again, once is enough
      } else if (customLanguage === 'resources' && !resourcesPlaceholders && placeholders) {
        const isResourcesPlaceholderSet = Boolean(sessionStorage.getItem('set-monaco-resources'));
        if (!isResourcesPlaceholderSet) {
          setPlaceholderAutocomplete(monacoRefCurrent, JSON.parse(placeholders), ['resources']);
          sessionStorage.setItem('set-monaco-resources', 'true');
        }
      }
    }
  }, [
    readOnly,
    monacoRefCurrent,
    fileExtension,
    customLanguage,
    placeholders,
    setVariablesPlaceholders,
    variablesPlaceholders,
    filesPlaceholders,
    setFilesPlaceholders,
    resourcesPlaceholders,
    setResourcesPlaceholders,
  ]);

  /* Do something before the editor gets mounted*/
  const handleEditorWillMount: BeforeMount = (monaco) => {
    if (monaco) {
      /** Create custom language named variables. If its already added in any previous render of editor, it will be ignored*/
      addCustomLanguage(monaco, 'variables');
      addCustomLanguage(monaco, 'files');
      addCustomLanguage(monaco, 'resources');
      setPlaceHolderSyntaxHighlighting(monaco.languages.getLanguages());
    }
  };

  /** Do some actions after editor is mounted and ready */
  const onMount: OnMount = (editor, monaco) => {
    /** in InputRef is passed, set editor object to it. `forwardRef` is not used as we dont have a dom element that can be set with ref*/
    if (inputRef) {
      inputRef.current = editor as unknown as HTMLTextAreaElement; // force it as textarea ( intrinsically monaco uses textarea)
    }
    /** set ctrl/cmd J to show suggestions*/
    registerSuggestionCommand(editor, monaco);
    /** register custom themes*/
    addThemes(monaco);
    /** set theme based on current value */
    if (theme) setTheme(monaco, theme);

    /** pass the editor and monaco object to refs for future usages */
    editorRef.current = editor;
    monacoRef.current = monaco;
    const model = editor.getModel();

    if (model) {
      /** Setting newline character to `LF` so that it will not take it automatically based on OS. In case Windows, since the default new line character is CRLF,
       * it causes infinite loop and other wierd behaviours*/
      model.setEOL(monaco.editor.EndOfLineSequence.LF);

      model.onDidChangeContent(() => {
        if (model.getLanguageId() === 'yaml' && schemaRef.current) {
          const modelValue = model.getValue();

          // Validate the parsed YAML against the JSON schema
          const errors = validateYaml(modelValue, schemaRef.current.schema);

          if (errors?.length) {
            monaco.editor.setModelMarkers(model, 'yaml', errors);
          } else {
            monaco.editor.setModelMarkers(model, 'yaml', []);
          }
        }
      });
    }

    /** handle keyDown */
    setOnKeyDownHandlerDisposer(
      editor.onKeyDown((e) => {
        if (
          e.code === 'Enter' &&
          !e.shiftKey &&
          usedForInput &&
          (!isMultiLineInput || insideAutocomplete(editor.getValue()))
        ) {
          /** if used in input, dont let user add new line*/
          /** This is a special case. When user is in placeholder brackets, dont propogate enter keypress*/
          e.preventDefault();
        } else if (onKeyDown) onKeyDown(getReactKeyboardEvent(e));
      })
    );

    if (onKeyUp) setOnKeyUpDisposer(editor.onKeyUp((e) => onKeyUp(getReactKeyboardEvent(e))));

    setFocusDisposer(
      editor.onDidFocusEditorText(() => {
        if (onFocus) onFocus(value ?? null); // when value is undefined return null as event handler expect string or null

        /** tabFocusMode is an option in monaco to determine if we should ( blur editor || enter a tab character) on pressing tab
         * if used for input and its disabled, we want it to be enabled.
         * If not using for input but enabled, we want to disable it
         */
        const tabFocusModeEnabled = editorRef.current.getOption(TAB_FOCUS_MODE_OPTION);
        if (
          (usedForInput && !isMultiLineInput && !tabFocusModeEnabled) ||
          (!usedForInput && tabFocusModeEnabled)
        )
          editorRef.current.trigger('Tab Focus Mode', 'editor.action.toggleTabFocusMode', '');
      })
    );

    setBlurDisposer(editor.onDidBlurEditorText(() => onBlur && onBlur(value ?? null))); // when value is undefined return null
  }; // END of onMount function

  /**
   * When user selects an option from autocomplete, they need to type '.' to get next option.
   * This may not be obvious, so we are adding a dot at the end automatically.
   * But we need to inform monaco to trigger suggestion. So, to do this, we have a local state to handle that .
   * We cannot do it immediately inside onChange as it needs to be done async.
   * They way it works is: In onChange, we check if change ends with '.' and we are inside placeholder , then set triggerSuggest to true.
   * When triggerSuggest is true, tell monaco trigger suggest box
   */
  useEffect(() => {
    if (triggerSuggest) {
      editorRef.current.trigger('Trigger suggestion', 'editor.action.triggerSuggest');
      setTriggerSuggest(false);
    }
  }, [triggerSuggest, setTriggerSuggest]);

  useEffect(() => {
    if (yamlSchema?.schema) {
      schemaRef.current = yamlSchema;
    }
  }, [yamlSchema]);

  return (
    <StyledEditor
      loading={<Spinner size={'large'} />}
      // key={renderKey}
      beforeMount={handleEditorWillMount}
      className={className}
      height={height}
      minHeight={minHeight}
      inputSize={inputSize}
      language={fileExtension && fileExtension === 'text' ? customLanguage : fileExtension}
      value={value}
      width={width}
      onMount={onMount}
      path={id}
      onChange={(val, ev) => {
        if (val === undefined || val === null) return;
        if (onChange) onChange(val, ev);
        // if change ends with '.' and we are in autocomplete , tell monaco to show suggest box
        if (ev.changes[0].text.endsWith('.') && insideAutocomplete(val)) {
          setTriggerSuggest(true);
        }
      }}
      onValidate={onValidate}
      wrapperProps={{ id, 'data-testid': dataTestid }}
      /** some styles and bars disabled when its used for input*/
      usedForInput={usedForInput}
      options={{
        // differentiate between readonly or edit mode
        cursorStyle: readOnly ? 'line-thin' : 'line',
        // dont show folding inside input. My own assumption , it can be removed if we get feedback
        folding: !usedForInput,
        // monaco expects a number in pixels. So convert rem to px and convert it to number
        fontSize: Number(stripUnit(remToPx(fontSize || units.fontSize.sm))) || 12,
        // cursor near progressbar
        hideCursorInOverviewRuler: usedForInput || readOnly,
        lineDecorationsWidth: usedForInput ? 0 : 5,
        lineNumbers: usedForInput ? 'off' : 'on',
        lineNumbersMinChars: usedForInput ? 0 : 2,
        minimap: { enabled: false },
        overviewRulerBorder: !usedForInput,
        fixedOverflowWidgets: true, // fixes contexual menu being cut in containers that have overflow:auto;
        readOnly,
        renderLineHighlight: usedForInput || readOnly ? 'none' : 'all',
        renderWhitespace: renderWhitespace ? 'all' : 'selection',
        // monaco by default lets user to scroll till only the last line is shown. This adds unnecessary schroll even when there is little text
        scrollBeyondLastLine: false,
        scrollBeyondLastColumn: 0,
        scrollbar: {
          vertical: 'auto',
          horizontal: 'hidden',
          alwaysConsumeMouseWheel: false,
        },
        tabIndex,
        wordWrap: 'on', // wrap long text to avoid horizontal scroll
        autoClosingBrackets: 'never',
        ...monacoOptions,
      }}
    />
  );
};
export default BasicEditor;
