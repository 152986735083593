import { Button, WalDropdownMenu, WalModal } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useRoleCreateMutation from '@src/hooks/react-query/roles/mutations/useRoleCreateMutation';
import useRoleDeleteMutation from '@src/hooks/react-query/roles/mutations/useRoleDeleteMutation';
import useRoleUpdateMutation from '@src/hooks/react-query/roles/mutations/useRoleUpdateMutation';
import { EnvTypeRoles, OrgRoles, Role } from '@src/models/role';
import { useWalhallForm } from '@src/utilities/form';

import ReadMore from '../../ReadMore';

interface ChangeEnvTypeRoleModalProps {
  user: Role<OrgRoles>;
  envTypeRole: EnvTypeRoles | undefined;
  envTypeId: string;
}

const ChangeEnvTypeRoleModal = ({ envTypeId, envTypeRole, user }: ChangeEnvTypeRoleModalProps) => {
  // i18n
  const { t } = useTranslation('orgMembers');
  const translations = t('ENVIRONMENT_TYPES_TAB');

  // Component state
  const [modalOpen, setModalOpen] = useState(false);

  // Form
  const formMethods = useWalhallForm({
    values: {
      role: envTypeRole || 'none',
    },
  });

  // React Query
  const {
    mutate: updateRole,
    isSuccess: isUpdated,
    isPending: isUpdating,
  } = useRoleUpdateMutation({
    id: envTypeId,
    type: 'env-types',
  });

  const {
    mutate: createRole,
    isSuccess: isCreated,
    isPending: isCreating,
  } = useRoleCreateMutation({
    id: envTypeId,
    type: 'env-types',
  });

  const {
    mutate: deleteRole,
    isSuccess: isDeleted,
    isPending: isDeleting,
  } = useRoleDeleteMutation({
    id: envTypeId,
    type: 'env-types',
  });

  useEffect(() => {
    if (isUpdated || isCreated || isDeleted) {
      setModalOpen(false);
    }
  }, [isUpdated, isCreated, isDeleted]);

  return (
    <>
      <Button variant={'secondary'} size={'small'} onClick={() => setModalOpen(true)}>
        {translations.CHNAGE_ROLE}
      </Button>
      <FormProvider {...formMethods}>
        <WalModal
          openState={[modalOpen, setModalOpen]}
          title={translations.CHANGE_ENV_TYPE_ROLE}
          handleFormSubmit={(formValues) => {
            const role = formValues.role;
            if (envTypeRole) {
              if (role === 'none') {
                deleteRole(user.id);
              } else {
                updateRole({ roleId: user.id, newRole: role });
              }
            } else {
              createRole({ role, id: user.id });
            }
          }}
          content={
            <>
              <WalDropdownMenu
                name={'role'}
                items={[
                  {
                    label: translations.NONE,
                    value: 'none',
                    id: 'none',
                  },
                  {
                    label: translations.DEPLOYER,
                    value: 'deployer',
                    id: 'deployer',
                  },
                ]}
              />
              <div className={'flex flex-column mb-xl'}>
                <span className={'py-xl'}>{translations.ENV_TYPE_ROLES}</span>
                <ReadMore />
              </div>
            </>
          }
          actions={{
            main: {
              text: 'Save',
              props: {
                type: 'submit',
                loading: isUpdating || isCreating || isDeleting,
              },
            },
            cancel: {},
          }}
        />
      </FormProvider>
    </>
  );
};

export default ChangeEnvTypeRoleModal;
