import { Button, Icon, WalLabel, WalModal } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useResourceClassDeleteMutation from '@src/hooks/react-query/resources/mutations/useResourceClassDeleteMutation';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import { ResourceClass } from '@src/models/resources';

import ResourcesTable from '../../../components/ResourcesTable/ResourcesTable';
import { getResourceDefinitionsWithClassInCriteria } from '../../../utils';

interface DeleteClassModalProps {
  resourceClass: ResourceClass;
}

export const DeleteClassModal = ({ resourceClass }: DeleteClassModalProps) => {
  // i18n
  const { t } = useTranslation();
  const classesTranslations = t('ACCOUNT_SETTINGS').RESOURCES.CLASSES;
  const uiTranslations = t('UI');

  // Component state
  const [open, setOpen] = useState(false);

  // React Query
  const { mutate: deleteClass, isPending: isDeleting } = useResourceClassDeleteMutation();
  const { data: resourceDefinitions = [] } = useResourceDefinitionsQuery();

  const defsWithClassInCriteria = getResourceDefinitionsWithClassInCriteria(
    resourceDefinitions,
    resourceClass
  );

  return (
    <>
      <Button
        variant={'secondary'}
        size={'small'}
        iconLeft={'delete'}
        ariaLabel={uiTranslations.DELETE}
        onClick={() => {
          setOpen(true);
        }}
      />
      <WalModal
        openState={[open, setOpen]}
        title={`${uiTranslations.DELETE} ${resourceClass.id}`}
        content={
          <>
            <div className={'mb-md'}>{classesTranslations.DELETE_INFO}</div>

            {defsWithClassInCriteria.length > 0 && (
              <>
                <div className={'mb-md'}>{classesTranslations.CLASS_USED_IN_DEFS}</div>
                <WalLabel>{classesTranslations.DEFINITIONS}</WalLabel>
                <ResourcesTable
                  resourceDefinitions={defsWithClassInCriteria}
                  hideFilters
                  readonly
                  maxDisplayedRows={5}
                  tableRowStyle={'base'}
                />
              </>
            )}

            <span className={'flex mb-md'}>
              <Icon name={'warning'} marginRight={'sm'} overrideColor={'yellow'} />
              {classesTranslations.ACTION_CANT_BE_UNDONE}
            </span>
          </>
        }
        actions={{
          main: {
            text: uiTranslations.DELETE,
            props: {
              variant: 'danger',
              loading: isDeleting,
              onClick: () => {
                deleteClass({ resourceClass });
              },
            },
          },
          cancel: {},
        }}
      />
    </>
  );
};
