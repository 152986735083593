import { WalInput } from '@humanitec/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AzureFieldsProps {
  isNew?: boolean;
  namePrefix: string;
}

const AzureFields = ({ isNew, namePrefix }: AzureFieldsProps) => {
  const { t } = useTranslation();
  const formTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS.ACCOUNT_MODAL.AZURE;
  const uiTranslations = t('UI');

  return (
    <WalInput
      name={namePrefix ? `${namePrefix}-token` : 'token'}
      isMonacoEditor
      monacoEditorFileExtension={'json'}
      label={formTranslations.TOKEN_LABEL}
      labelAbove={!isNew}
      placeholder={!isNew ? '••••••••••' : undefined}
      labelExtensionText={
        isNew ? formTranslations.TOKEN_PLACEHOLDER : formTranslations.TOKEN_PLACEHOLDER_EDITING
      }
      required={isNew}
      validate={{
        validateJSON: (value: string) => {
          try {
            return isNew ? JSON.parse(value) : true;
          } catch (e) {
            return uiTranslations.INVALID_JSON;
          }
        },
      }}
      rows={8}
    />
  );
};

export default AzureFields;
