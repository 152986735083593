import { Button, Icon, Radio, WalCard, WarningSection } from '@humanitec/ui-components';
import React, { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import WorkloadOrResourceCardTitle from '@src/components/shared/CloneModal/components/ReviewCloneDetails/components/WorkloadOrResourceCardTitle';
import { TitleRow } from '@src/components/shared/CloneModal/components/ReviewCloneDetails/ReviewCloneDetails';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentObject } from '@src/models/deployment-object';
import { DeploymentSet } from '@src/models/deployment-set';
import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';

interface RemoveWorkloadsAccordionContentProps {
  defaultCloneDraftContent: Partial<DeploymentDelta>;
  defaultCloneDraftWithGroupedResourceUpdates: Partial<DeploymentDelta>;
  cloneDraftContentState: [
    Partial<DeploymentDelta>,
    Dispatch<SetStateAction<Partial<DeploymentDelta>>>,
  ];
  addResourceChangeInCloneDraft: (id: string) => void;
  removeResourceChangeFromCloneDraft: (id: string) => void;
  targetDeploymentSet?: DeploymentSet;
  targetEnv?: Environment;
  fromDeployment?: DeploymentObject;
}
const RemoveWorkloadsAccordionContent = ({
  targetEnv,
  defaultCloneDraftContent,
  defaultCloneDraftWithGroupedResourceUpdates,
  fromDeployment,
  cloneDraftContentState,
  addResourceChangeInCloneDraft,
  removeResourceChangeFromCloneDraft,
  targetDeploymentSet,
}: RemoveWorkloadsAccordionContentProps) => {
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const modalTranslations = tViewEnvironment('DEPLOYS').DEPLOYMENT_CARD.CLONE_MODAL;
  const { appId, orgId } = useParams<keyof MatchParams>() as MatchParams;
  const [cloneDraftContent, setCloneDraftContent] = cloneDraftContentState;

  const removeOrKeepWorkloadInCloneDraft = (id: string, removeOrKeep?: 'remove' | 'keep') => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (removeOrKeep === 'remove' && !cloneDraftContent.modules?.remove?.includes(id)) {
      cloneDraftContent.modules?.remove?.push(id);
    }
    if (removeOrKeep === 'keep' && cloneDraftContent.modules?.remove?.includes(id)) {
      const index = cloneDraftContent.modules?.remove?.indexOf(id);
      cloneDraftContent.modules?.remove?.splice(index, 1);
    }
    setCloneDraftContent(newCloneDraftContent);
  };

  const setAllRemoveWorkloadsAndResources = (removeAll?: boolean) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent.modules) {
      newCloneDraftContent.modules.remove = removeAll
        ? defaultCloneDraftContent.modules?.remove && [...defaultCloneDraftContent.modules.remove]
        : [];
      newCloneDraftContent.shared = removeAll
        ? defaultCloneDraftWithGroupedResourceUpdates.shared && [
            ...defaultCloneDraftWithGroupedResourceUpdates.shared,
          ]
        : defaultCloneDraftWithGroupedResourceUpdates.shared?.filter(
            (change) => change.op !== 'remove'
          );
    }
    setCloneDraftContent(newCloneDraftContent);
  };

  const renderRemoveWorkloadOrResourceCard = (
    id: string,
    isWorkload: boolean,
    subtitle?: string
  ) => (
    <WalCard className={'mb-sm'} cardStyle={'base'} key={id}>
      <TitleRow className={'mb-md'}>
        {targetEnv?.last_deploy?.id && (
          <WorkloadOrResourceCardTitle
            orgId={orgId}
            appId={appId}
            envId={targetEnv.id}
            deploymentId={targetEnv?.last_deploy?.id}
            id={id}
            isWorkload={isWorkload}
            subtitle={subtitle}
          />
        )}
        <div className={'flex-centered'}>
          <Icon name={'warning'} overrideColor={'yellow'} size={18} marginRight={'lg'} />
          <Radio
            name={`remove-${id}`}
            label={`${modalTranslations.REMOVE_FROM} ${targetEnv?.id}`}
            value={`remove-${id}`}
            className={'mr-md'}
            defaultChecked={
              isWorkload
                ? cloneDraftContent.modules?.remove?.includes(id)
                : Boolean(cloneDraftContent.shared?.find((change) => change.path === `/${id}`))
            }
            onClick={() =>
              isWorkload
                ? removeOrKeepWorkloadInCloneDraft(id, 'remove')
                : addResourceChangeInCloneDraft(`/${id}`)
            }
          />
          <Radio
            name={`remove-${id}`}
            label={`${modalTranslations.KEEP_IN} ${targetEnv?.id}`}
            value={`keep-${id}`}
            defaultChecked={
              isWorkload
                ? !cloneDraftContent.modules?.remove?.includes(id)
                : !Boolean(cloneDraftContent.shared?.find((change) => change.path === `/${id}`))
            }
            onClick={() =>
              isWorkload
                ? removeOrKeepWorkloadInCloneDraft(id, 'keep')
                : removeResourceChangeFromCloneDraft(`/${id}`)
            }
          />
        </div>
      </TitleRow>
      <WarningSection disableDefaultMargin>
        <p>
          {isWorkload
            ? modalTranslations.REMOVE_WORKLOAD_WARNING1
            : modalTranslations.REMOVE_RESOURCE_WARNING1}{' '}
          {fromDeployment?.env_id}
        </p>
        <Trans
          defaults={
            isWorkload
              ? modalTranslations.REMOVE_WORKLOAD_WARNING2
              : modalTranslations.REMOVE_RESOURCE_WARNING2
          }
          values={{ envId: targetEnv?.id }}>
          <span />
        </Trans>
      </WarningSection>
    </WalCard>
  );

  return (
    <div>
      <p className={'txt-sm txt-translucent mb-md'}>
        {modalTranslations.REMOVE_WORKLOADS_SUBHEADING1} {targetEnv?.id}
      </p>
      <p className={'txt-sm txt-translucent mb-md'}>
        {modalTranslations.REMOVE_WORKLOADS_SUBHEADING2}
      </p>
      <p className={'txt-sm txt-translucent mb-md'}>
        <span className={'mr-md'}>{modalTranslations.SET_ALL_TO}</span>
        <Button variant={'borderless-blue'} onClick={() => setAllRemoveWorkloadsAndResources(true)}>
          {modalTranslations.REMOVE}
        </Button>
        <Button variant={'borderless-blue'} onClick={() => setAllRemoveWorkloadsAndResources()}>
          {modalTranslations.KEEP}
        </Button>
      </p>
      {defaultCloneDraftContent.modules?.remove?.map((workloadId) =>
        renderRemoveWorkloadOrResourceCard(
          workloadId,
          true,
          targetDeploymentSet?.modules[workloadId]?.profile
        )
      )}
      {defaultCloneDraftWithGroupedResourceUpdates.shared
        ?.filter((resourceChange) => resourceChange.op === 'remove')
        .map((resourceChange) =>
          renderRemoveWorkloadOrResourceCard(
            resourceChange.path.slice(1),
            false,
            targetDeploymentSet?.shared?.[resourceChange.path.slice(1)]?.type
          )
        )}
    </div>
  );
};

export default RemoveWorkloadsAccordionContent;
