import { Button, MultistepModal } from '@humanitec/ui-components';
import { useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useResourceClassCreateMutation from '@src/hooks/react-query/resources/mutations/useResourceClassCreateMutation';
import { ResourceType } from '@src/models/resources';
import { useWalhallForm } from '@src/utilities/form';

import { AddResourceClassForm } from '../components/AddResourceClassForm';
import SelectResourceTypeStep from '../components/SelectResourceTypeStep';

export const AddResourceClassModal = () => {
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedResourceType, setSelectedResourceType] = useState<ResourceType>();
  const createButtonRef = useRef<HTMLButtonElement | null>(null);

  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES.CLASSES;
  const uiTranslations = t('UI');

  // Form
  const formMethods = useWalhallForm({ defaultValues: { id: '', description: '' } });
  const { reset, setError, handleSubmit } = formMethods;

  // React Query
  const {
    mutate: createClass,
    isSuccess: resourceClassCreated,
    error: resourceClassCreateError,
    reset: resetCreateResourceClassMutation,
  } = useResourceClassCreateMutation();

  const handleCreateClick = () => {
    createButtonRef.current?.click();
  };

  const submitForm = (values: { id: string; description: string }) => {
    createClass({
      resourceTypeId: selectedResourceType?.type || '',
      payload: values,
    });
  };

  useEffect(() => {
    if (resourceClassCreated) {
      setOpen(false);
      setCurrentStep(0);
      reset({});
      setSelectedResourceType(undefined);
    }
  }, [resourceClassCreated, reset]);

  useEffect(() => {
    if (!open) {
      resetCreateResourceClassMutation();
      setCurrentStep(0);
      reset({});
      setSelectedResourceType(undefined);
    }
  }, [open, reset, resetCreateResourceClassMutation]);

  useEffect(() => {
    if (resourceClassCreateError?.response?.status === 409) {
      setError('id', { message: resourceClassCreateError?.response.data.message });
    }
  }, [resourceClassCreateError, setError]);

  return (
    <>
      <Button iconLeft={'plus'} onClick={() => setOpen(true)}>
        {resourcesTranslations.ADD_RESOURCE_CLASS}
      </Button>
      {open && (
        <MultistepModal
          openState={[open, setOpen]}
          currentStepState={[currentStep, setCurrentStep]}
          steps={[
            {
              title: resourcesTranslations.CREATE_RESOURCE_CLASS,
              content: (
                <SelectResourceTypeStep
                  setCurrentStep={setCurrentStep}
                  setSelectedResourceType={setSelectedResourceType}
                  useFilter={'direct'}
                />
              ),
              actions: [
                {
                  label: uiTranslations.CLOSE,
                },
              ],
            },
            {
              title: resourcesTranslations.DEFINE_CLASS,
              content: (
                <FormProvider {...formMethods}>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <AddResourceClassForm />
                    <button ref={createButtonRef} type={'submit'} hidden />
                  </form>
                </FormProvider>
              ),
              actions: [
                {
                  label: uiTranslations.BACK,
                },
                {
                  label: uiTranslations.CREATE,
                  variant: 'primary',
                  callback: handleCreateClick,
                },
              ],
            },
          ]}
        />
      )}
    </>
  );
};
