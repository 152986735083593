import { Spinner, TabGroup, TabGroupOptionProps, Text, WalLabel } from '@humanitec/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import useGetUserByIdQuery from '@src/hooks/react-query/user/useGetUserByIdQuery';
import { MatchParams } from '@src/models/routing';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

const Container = styled(PageContainer)`
  flex-direction: column;
  overflow: auto;
  margin-bottom: ${units.margin.lg};
`;

const SpinnerWrapper = styled.div`
  height: 100%;
`;

type ResourceTabs = 'usage' | 'matching-criteria' | 'configuration' | 'delete';

const ResourceDefinition = () => {
  // Router hooks
  const { defId } = useParams<keyof MatchParams>() as MatchParams;

  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  const { data: resourceDefinitions, isPending: isResourceDefinitionLoading } =
    useResourceDefinitionsQuery();
  const resourceDefinition = useMemo(
    () => resourceDefinitions?.find((r) => r.id === defId),
    [resourceDefinitions, defId]
  );

  const { data: createdByUser } = useGetUserByIdQuery(resourceDefinition?.created_by || '');

  const tabOptions: TabGroupOptionProps<ResourceTabs>[] = [
    {
      label: resourcesTranslations.USAGE,
      value: 'usage',
      link: {
        to: `usage`,
      },
    },
    {
      label: resourcesTranslations.MATCHING_CRITERIA,
      value: 'matching-criteria',
      link: {
        to: `matching-criteria`,
      },
    },
    {
      label: resourcesTranslations.CONFIGURATION,
      disabled: resourceDefinition?.is_default,
      disabledInfo: resourcesTranslations.CONFIGURATION_DISABLED,
      value: 'configuration',
      link: {
        to: `configuration`,
      },
    },
    {
      label: resourcesTranslations.DELETE,
      disabled: resourceDefinition?.is_default,
      disabledInfo: resourcesTranslations.DELETE_DISABLED,
      value: 'delete',
      link: {
        to: `delete`,
      },
    },
  ];

  if (isResourceDefinitionLoading) {
    return (
      <SpinnerWrapper className={'flex justify-center align-center'}>
        <Spinner size={'large'} />
      </SpinnerWrapper>
    );
  }

  return (
    <Container>
      <PageHeader />
      {!resourceDefinition?.is_default && (
        <div className={'flex-centered mb-xl'} data-testid={'resource-definition-creation-info'}>
          <div className={'flex-column mr-xl'}>
            <WalLabel>{resourcesTranslations.RESOURCE_TYPE}</WalLabel>
            <Text size={'base'} className={'mt-sm flex-centered'}>
              {resourceDefinition?.type}
            </Text>
          </div>
          <div className={'flex-column mr-xl'}>
            <WalLabel>{resourcesTranslations.CREATED_AT}</WalLabel>
            <Text size={'base'} className={'mt-sm flex-centered'}>
              {formatDate(
                resourceDefinition?.created_at,
                DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE
              )}
            </Text>
          </div>
          {createdByUser?.name && (
            <div className={'flex-column mr-xl'}>
              <WalLabel>{resourcesTranslations.CREATED_BY}</WalLabel>
              <Text size={'base'} className={'mt-sm flex-centered'}>
                {createdByUser.name}
              </Text>{' '}
            </div>
          )}
        </div>
      )}
      <TabGroup options={tabOptions} size={'large'} />
      <Outlet />
    </Container>
  );
};

export default ResourceDefinition;
