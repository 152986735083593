import {
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
  EdgeChange,
  EdgeMarker,
  Node,
  NodeChange,
  ReactFlow,
  useNodesInitialized,
  useReactFlow,
} from '@xyflow/react';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

// eslint-disable-next-line import/no-internal-modules
import '@xyflow/react/dist/style.css';

import { darkPalette } from '@src/theme/color-palette';

import { FlowDiagramProps } from './FlowDiagram';
import { getAllIncomers, getAllOutgoers, getCenterPosition, getLayoutedElements } from './utils';

const CustomControls = styled(Controls)`
  .react-flow__controls-button {
    background: ${({ theme }) => theme.color.baseLayer};
    border-bottom: 1px solid ${({ theme }) => theme.color.baseOutline};
    fill: ${({ theme }) => theme.color.text};
  }
`;
const ReactFlowInternal = <NodeData extends Record<string, unknown>>({
  nodes: initialNodes,
  edges: initialEdges,
  showControls,
  nodeTypes,
  onNodeClick,
}: FlowDiagramProps<NodeData>) => {
  const [nodes, setNodes] = useState<Node<NodeData>[]>(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [dagreLayoutInitialised, setDagreLayoutInitialised] = useState<boolean>();
  const [initialZoomLevel, setInitialZoomLevel] = useState<number>(0.8);
  const { getNodes, getEdges, setCenter, fitView, getZoom } = useReactFlow<Node<NodeData>>();
  const nodesInitialized = useNodesInitialized();

  useEffect(() => {
    if (nodesInitialized) {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements<NodeData>(
        getNodes(),
        getEdges()
      );
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
      setDagreLayoutInitialised(true);
    }
  }, [nodesInitialized, getNodes, getEdges]);

  useEffect(() => {
    // fit the graph in the viewport after it has been initialised
    if (dagreLayoutInitialised) {
      fitView({ minZoom: 0.6, maxZoom: 1 });
      setInitialZoomLevel(getZoom());
    }
  }, [fitView, dagreLayoutInitialised, getZoom]);

  const onNodesChange = useCallback(
    (changes: NodeChange<Node<NodeData>>[]) =>
      setNodes((nds) => applyNodeChanges<Node<NodeData>>(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  /**
   * Highlights the nodes and edges connected to a specific node
   *
   * @param node
   */
  const highlightPath = (node: Node) => {
    if (node && nodes && edges) {
      const allIncomers = getAllIncomers(node, nodes, edges);
      const allOutgoers = getAllOutgoers(node, nodes, edges);
      if (node.measured?.width && node.measured?.height) {
        const centerPosition = getCenterPosition([...allIncomers, ...allOutgoers, node]);
        // move viewport to the selected node
        setCenter(centerPosition.x + node.measured.width / 2, centerPosition.y, {
          zoom: initialZoomLevel + 0.05,
          duration: 1000,
        });
      }
      const incomerIds = allIncomers.map((i) => i.id);
      const outgoerIds = allOutgoers.map((o) => o.id);

      const highlightedNodeIds: string[] = [];

      setNodes((prevNodes) => {
        return prevNodes?.map((currentNode) => {
          const highlight =
            currentNode.id === node.id ||
            incomerIds.includes(currentNode.id) ||
            outgoerIds.includes(currentNode.id);
          if (highlight) {
            highlightedNodeIds.push(currentNode.id);
          }
          currentNode = {
            ...currentNode,
            data: {
              ...currentNode.data,
              selected: highlight,
            },
            style: {
              ...currentNode.style,
              opacity: highlight ? 1 : 0.25,
            },
          };
          return currentNode;
        });
      });
      setEdges((prevEdges) => {
        return prevEdges?.map((currentEdge) => {
          const highlight =
            (outgoerIds.includes(currentEdge.target) &&
              highlightedNodeIds.includes(currentEdge.source)) ||
            (incomerIds.includes(currentEdge.source) &&
              highlightedNodeIds.includes(currentEdge.target));
          currentEdge = {
            ...currentEdge,
            markerEnd: {
              ...(currentEdge.markerEnd as EdgeMarker),
              color: highlight ? darkPalette.main : undefined,
            },
            style: {
              ...currentEdge.style,
              stroke: highlight ? darkPalette.main : darkPalette.baseTransparent,
            },
            animated: highlight,
          };
          return currentEdge;
        });
      });
    }
  };

  return (
    <ReactFlow<Node<NodeData>>
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      maxZoom={2}
      minZoom={0.6}
      nodesDraggable={false}
      onNodeClick={(e, node) => {
        highlightPath(node);
        if (onNodeClick) {
          onNodeClick(node);
        }
      }}
      onPaneClick={() => {
        setNodes(initialNodes);
        setEdges(initialEdges);
        fitView({ minZoom: 0.6, maxZoom: 1, duration: 1000 });
      }}
      defaultViewport={{ x: 0, y: 0, zoom: initialZoomLevel }}>
      {showControls && <CustomControls showInteractive={false} />}
    </ReactFlow>
  );
};

export default ReactFlowInternal;
