import { CloseIcon, EmptyStateCard, FlowDiagram, Spinner, WalCard } from '@humanitec/ui-components';
import { Edge, MarkerType, Node } from '@xyflow/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import ResourceCardDetails from '@src/components/shared/ResourceDependencyList/components/ResourceCardDetails/ResourceCardDetails';
import ResourceNode from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/ResourceDependencyGraph/components/ResourceNode';
import ResourceIcon from '@src/containers/Orgs/Resources/components/ResourceIcon/ResourceIcon';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentQuery from '@src/hooks/react-query/environments/queries/useDeploymentQuery';
import useEnvironmentResourcesQuery from '@src/hooks/react-query/environments/queries/useEnvironmentResourcesQuery';
import useResourceDependencyGraphQuery from '@src/hooks/react-query/resources/queries/useResourceDependencyGraphQuery';
import { useResourceDeploymentTimes } from '@src/hooks/useResourceDeploymentTimes';
import { ActiveResource, ResourceDependencyGraphNode, ResourceTypes } from '@src/models/resources';
import { units } from '@src/styles/variables';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  row-gap: ${units.margin.md};
  column-gap: ${units.margin.md};
  margin-bottom: ${units.margin.md};
  overflow: auto;
`;

const SideBar = styled(WalCard)`
  flex: 1 1 180px;
  border: 1px solid ${({ theme }) => theme.color.baseOutline};
  border-radius: 4px;
`;

const ReactFlowContainer = styled.div`
  flex-grow: 1;
  min-width: 80%;
  min-height: 70%;
  border: 1px solid ${({ theme }) => theme.color.baseOutline};
  border-radius: 4px;
`;

const FullWidthEmptyCard = styled(EmptyStateCard)`
  width: 100%;
`;
const nodeTypes = {
  resourceNode: ResourceNode,
};

interface ResourceNodeData extends Record<string, unknown> {
  res_id: string;
  guresid: string;
  class: string;
  def_id: string;
  type: ResourceTypes;
  provisionTime?: string;
}

const ResourceDependencyGraph = () => {
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // i18n
  const { t } = useTranslation();
  const resourceDependencyGraphTranslations = t('RESOURCE_DEPENDENCY_GRAPH');

  // state
  const [selectedResource, setSelectedResource] = useState<ActiveResource>();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);

  const { data: deployment } = useDeploymentQuery();
  const { data: activeResources } = useEnvironmentResourcesQuery();
  const { data: resourceDependencyGraph, isFetching: resourceDependencyGraphFetching } =
    useResourceDependencyGraphQuery(deployment?.dependency_graph_id);

  const provisionTimes = useResourceDeploymentTimes(
    deployment,
    activeResources,
    resourceDependencyGraph?.nodes
  );

  const nodes: Node<ResourceNodeData>[] = useMemo(
    () =>
      resourceDependencyGraph?.nodes.map((node) => ({
        id: node.guresid,
        position: { x: 0, y: 0 },
        type: 'resourceNode',
        data: {
          res_id: node.id,
          guresid: node.guresid,
          class: node.class,
          def_id: node.def_id,
          type: node.type,
          provisionTime: provisionTimes[node.guresid]?.provision_time,
        },
      })) || [],
    [resourceDependencyGraph, provisionTimes]
  );

  const edges = useMemo(
    () =>
      resourceDependencyGraph?.nodes.reduce((acc: Edge[], node: ResourceDependencyGraphNode) => {
        return node.depends_on.length > 0
          ? [
              ...acc,
              ...node.depends_on.map((dependencyGuresId) => ({
                id: `${node.guresid}-${dependencyGuresId}`,
                source: node.guresid,
                target: dependencyGuresId,
                markerEnd: { type: MarkerType.ArrowClosed, strokeWidth: 5 },
              })),
            ]
          : acc;
      }, []) || [],
    [resourceDependencyGraph]
  );

  const handleNodeClick = (node: Node<ResourceNodeData>) => {
    setSelectedResource(
      activeResources?.find((activeResource) => activeResource.gu_res_id === node.data.guresid)
    );
    setSidebarOpen(true);
  };

  return (
    <Container>
      <Wrapper className={'flex-column'}>
        {resourceDependencyGraph ? (
          <>
            <ReactFlowContainer>
              <FlowDiagram
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                showControls
                onNodeClick={handleNodeClick}
              />
            </ReactFlowContainer>
            {sidebarOpen && selectedResource && (
              <SideBar>
                <CloseIcon onClick={() => setSidebarOpen(false)} />
                <div className={'flex-row flex-centered mb-lg'}>
                  <ResourceIcon type={selectedResource.type} hasMargin />
                  <span className={'mr-md'}>{selectedResource.res_id}</span>
                </div>
                <ResourceCardDetails
                  activeResource={selectedResource}
                  provisionTime={provisionTimes[selectedResource.gu_res_id]?.provision_time}
                  hideResourceId
                />
              </SideBar>
            )}
          </>
        ) : resourceDependencyGraphFetching ? (
          <Spinner />
        ) : draftModeActive ? (
          <FullWidthEmptyCard>
            {resourceDependencyGraphTranslations.GRAPH_UNAVAILABLE_IN_DRAFT}
          </FullWidthEmptyCard>
        ) : (
          <FullWidthEmptyCard>
            {resourceDependencyGraphTranslations.NO_GRAPH_AVAILABLE}
          </FullWidthEmptyCard>
        )}
      </Wrapper>
    </Container>
  );
};

export default ResourceDependencyGraph;
