import { rem } from 'polished';
import React, { KeyboardEvent, MouseEvent } from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';

import { units } from '@src/styles/variables';

import AivenSVG from '../../assets/svg/aiven.svg?react';
import AmazonSVG from '../../assets/svg/amazon.svg?react';
import AMQPSVG from '../../assets/svg/amqp.svg?react';
import AppCriteriaSVG from '../../assets/svg/app-criteria.svg?react';
import ArrowSVG from '../../assets/svg/arrow.svg?react';
import AWSSVG from '../../assets/svg/aws.svg?react';
import AzureSVG from '../../assets/svg/azure.svg?react';
import AzureSbsSVG from '../../assets/svg/azure-sbs.svg?react';
import BaseEnvSVG from '../../assets/svg/base-env.svg?react';
import BitbucketSVG from '../../assets/svg/bitbucket.svg?react';
import CassandraSVG from '../../assets/svg/cassandra.svg?react';
import CheckMarkSVG from '../../assets/svg/check-mark.svg?react';
import CircleCISVG from '../../assets/svg/circle-ci.svg?react';
import ClockSVG from '../../assets/svg/clock.svg?react';
import CloseSVG from '../../assets/svg/close.svg?react';
import CloudflareSVG from '../../assets/svg/cloudflare.svg?react';
import CommentsSVG from '../../assets/svg/comments.svg?react';
import CookieSVG from '../../assets/svg/cookie.svg?react';
import CopySVG from '../../assets/svg/copy.svg?react';
import CrossSVG from '../../assets/svg/cross.svg?react';
import BaseSVG from '../../assets/svg/default-workload.svg?react';
import DeleteSVG from '../../assets/svg/delete-icon.svg?react';
import DNSSVG from '../../assets/svg/dns.svg?react';
import DocumentsSVG from '../../assets/svg/documents.svg?react';
import DoubleRightArrowSVG from '../../assets/svg/double-right-arrow.svg?react';
import EditSVG from '../../assets/svg/edit.svg?react';
import ElasticSearchSVG from '../../assets/svg/elasticsearch.svg?react';
import EmptyDirSVG from '../../assets/svg/emptyDir.svg?react';
import EnvCriteriaSVG from '../../assets/svg/env-criteria.svg?react';
import FilterSVG from '../../assets/svg/filter.svg?react';
import GCPSVG from '../../assets/svg/gcp.svg?react';
import GenericResourceSVG from '../../assets/svg/generic-resource-type.svg?react';
import GitHubSVG from '../../assets/svg/github.svg?react';
import GoogleSVG from '../../assets/svg/google.svg?react';
import HappySVG from '../../assets/svg/happy.svg?react';
import HistorySVG from '../../assets/svg/history.svg?react';
import HpaSVG from '../../assets/svg/hpa.svg?react';
import HumanitecSVG from '../../assets/svg/humanitec.svg?react';
import InfoSVG from '../../assets/svg/info-icon.svg?react';
import IngressSVG from '../../assets/svg/ingress.svg?react';
import KubernetesSVG from '../../assets/svg/kubernetes.svg?react';
import LinkSVG from '../../assets/svg/link.svg?react';
import LockSVG from '../../assets/svg/lock.svg?react';
import LoggingSVG from '../../assets/svg/logging.svg?react';
import LogoutSVG from '../../assets/svg/logout.svg?react';
import MariaDBSVG from '../../assets/svg/mariadb.svg?react';
import MongoDBSVG from '../../assets/svg/mongodb.svg?react';
import MySQLSVG from '../../assets/svg/mysql.svg?react';
import NamespaceSVG from '../../assets/svg/namespace.svg?react';
import AccountSVG from '../../assets/svg/navbar/account.svg?react';
import ApiTokensSVG from '../../assets/svg/navbar/api-tokens.svg?react';
import ApplicationsSVG from '../../assets/svg/navbar/applications.svg?react';
import CloudAccountsSVG from '../../assets/svg/navbar/cloud-accounts.svg?react';
import ContainerImagesSVG from '../../assets/svg/navbar/container-images.svg?react';
import DocsSVG from '../../assets/svg/navbar/docs.svg?react';
import EnvironmentTypesSVG from '../../assets/svg/navbar/environment-types.svg?react';
import EnvironmentsSVG from '../../assets/svg/navbar/environments.svg?react';
import HideMenuSVG from '../../assets/svg/navbar/hide-menu.svg?react';
import OrgMembers from '../../assets/svg/navbar/org-members.svg?react';
import OrganizationSVG from '../../assets/svg/navbar/organization.svg?react';
import PipelineRunsSVG from '../../assets/svg/navbar/pipeline-runs.svg?react';
import RegistriesSVG from '../../assets/svg/navbar/registries.svg?react';
import ResourcesSVG from '../../assets/svg/navbar/resources.svg?react';
import ShortcutsSVG from '../../assets/svg/navbar/shortcuts.svg?react';
import VariablesSVG from '../../assets/svg/navbar/variables.svg?react';
import PadlockSVG from '../../assets/svg/padlock.svg?react';
import PauseSVG from '../../assets/svg/pause.svg?react';
import PauseInCircleSVG from '../../assets/svg/pause-in-circle.svg?react';
import PersonSVG from '../../assets/svg/person.svg?react';
import PinSVG from '../../assets/svg/pin.svg?react';
import PlaySVG from '../../assets/svg/play.svg?react';
import PlusSVG from '../../assets/svg/plus.svg?react';
import PostgresSVG from '../../assets/svg/postgresql.svg?react';
import ProfileSVG from '../../assets/svg/profile.svg?react';
import PulseSVG from '../../assets/svg/pulse.svg?react';
import QuestionInCircleSVG from '../../assets/svg/question.svg?react';
import Queued from '../../assets/svg/queued.svg?react';
import RabbitMqSVG from '../../assets/svg/rabbitmq.svg?react';
import RedisSVG from '../../assets/svg/redis.svg?react';
import RegistrySVG from '../../assets/svg/registry.svg?react';
import RemoveSVG from '../../assets/svg/remove.svg?react';
import ResourceCriteriaSVG from '../../assets/svg/resource-criteria.svg?react';
import RobotSVG from '../../assets/svg/robot.svg?react';
import S3SVG from '../../assets/svg/s3.svg?react';
import SearchSVG from '../../assets/svg/search.svg?react';
import SkullSVG from '../../assets/svg/skull.svg?react';
import SSOSVG from '../../assets/svg/sso.svg?react';
import StarSVG from '../../assets/svg/star.svg?react';
import TagSVG from '../../assets/svg/tag.svg?react';
import ThreeDotsSVG from '../../assets/svg/three-dots.svg?react';
import TLSCertSVG from '../../assets/svg/tls-cert.svg?react';
import VolumeSVG from '../../assets/svg/volume.svg?react';
import WarningSVG from '../../assets/svg/warning.svg?react';
import WorkloadSVG from '../../assets/svg/workload.svg?react';

const SVGImage = styled.img<{ size: IconSizes }>`
  width: ${({ size }) => rem(size)};
  height: ${({ size }) => rem(size)};
`;

export type IconSizes = 12 | 14 | 16 | 18 | 20 | 24 | 30 | 32 | 40 | 50 | 64;
export type IconColorOverrides =
  | 'alert'
  | 'alert-brighter'
  | 'main'
  | 'text'
  | 'text-translucent'
  | 'green'
  | 'yellow'
  | 'main-brighter';

export interface IconProps {
  name?: IconNames;
  url?: {
    src: string;
    alt: string;
  };
  size?: IconSizes;
  className?: string;
  marginLeft?: 'sm' | 'md';
  marginRight?: 'xs' | 'sm' | 'md' | 'lg';
  disableInvert?: boolean;
  reverseInvert?: boolean;
  pointer?: boolean;
  overrideColor?: IconColorOverrides;
  /** changes opacity to disabled style */
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  onMouseEnter?: (event: MouseEvent) => void;
  iconRef?: React.Ref<any>;
  tabIndex?: number;
  ariaLabel?: string;
  dataTestId?: string;
}

interface IconWrapperProps {
  size?: IconSizes;
  marginLeft?: 'sm' | 'md';
  marginRight?: 'xs' | 'sm' | 'md' | 'lg';
  disableInvert?: boolean;
  reverseInvert?: boolean;
  pointer?: boolean;
  overrideColor?: IconColorOverrides;
  /* changes opacity to disabled style */
  disabled?: boolean;
  /* to overide color of only the first svg path. Used for icons where changing the color of all paths distrupts the icon appearance */
  limitColorOverrideToFirstChild?: boolean;
}

export const iconNamesArray = [
  'account',
  'aiven',
  'amqp',
  'api-tokens',
  'app-criteria',
  'applications',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'aws',
  'azure',
  'azure-sbs',
  'base-env',
  'bitbucket',
  'cassandra',
  'checkmark',
  'circle-ci',
  'clock',
  'close',
  'cloud-accounts',
  'cloudflare',
  'container-images',
  'comments',
  'cookie',
  'copy',
  'cross',
  'default-workload',
  'delete',
  'dns',
  'docs',
  'documents',
  'double-right-arrow',
  'edit',
  'elasticsearch',
  'emptyDir',
  'env-criteria',
  'environment-types',
  'environments',
  'gcp',
  'github',
  'google',
  'happy',
  'hide-menu',
  'history',
  'horizontal-pod-autoscaler',
  'humanitec',
  'info',
  'ingress',
  'k8s-cluster',
  'k8s-namespace',
  'kubernetes',
  'link',
  'lock',
  'logging',
  'logout',
  'mariadb',
  'mongodb',
  'mysql',
  'org-members',
  'organization',
  'padlock',
  'pause',
  'pause-in-circle',
  'person',
  'pipeline-runs',
  'pin',
  'play',
  'plus',
  'postgres',
  'profile',
  'pulse',
  'rabbitmq',
  'redis',
  'registries',
  'registry',
  'remove',
  'resource-criteria',
  'resources',
  'robot',
  's3',
  'search',
  'shortcuts',
  'skull',
  'star',
  'tag',
  'three-dots',
  'tls-cert',
  'variables',
  'volume',
  'warning',
  'workload',
  'amazon',
  'generic-resource',
  'sso',
  'filter',
  'question',
  'queued',
] as const;
// Info on why `as const` is used - https://stackoverflow.com/questions/44497388/typescript-array-to-string-literal-type

export type IconNames = (typeof iconNamesArray)[number];

const modifyColor = (overrideColor: IconColorOverrides, theme: DefaultTheme) =>
  overrideColor === 'alert'
    ? theme.color.alert
    : overrideColor === 'alert-brighter'
      ? theme.color.alertBrighter
      : overrideColor === 'green'
        ? theme.color.green
        : overrideColor === 'yellow'
          ? theme.color.yellow
          : overrideColor === 'main'
            ? theme.color.main
            : overrideColor === 'text'
              ? theme.color.text
              : overrideColor === 'text-translucent'
                ? theme.color.textTranslucent
                : overrideColor === 'main-brighter' && theme.color.mainBrighter;

const IconWrapper = styled.span<IconWrapperProps>`
  display: block;
  height: ${({ size }) => (size ? rem(size) : rem(16))};
  width: ${({ size }) => (size ? rem(size) : rem(16))};
  margin-right: ${({ marginRight }) => marginRight && units.margin[marginRight]};
  margin-left: ${({ marginLeft }) => marginLeft && units.margin[marginLeft]};
  line-height: 1;

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}

  svg {
    ${({ theme, disableInvert, reverseInvert, overrideColor }) =>
      !disableInvert &&
      !theme.dark &&
      !reverseInvert &&
      !overrideColor &&
      css`
        filter: invert(0.75);
      `}

    ${({ theme, disableInvert, reverseInvert, overrideColor }) =>
      !disableInvert &&
      reverseInvert &&
      theme.dark &&
      !overrideColor &&
      css`
        filter: invert(0.75);
      `}
    
      ${({ overrideColor, limitColorOverrideToFirstChild }) =>
      overrideColor &&
      limitColorOverrideToFirstChild &&
      css`
        path:first-child {
          fill: ${({ theme }) => modifyColor(overrideColor, theme)};
        }
      `}

    ${({ overrideColor, limitColorOverrideToFirstChild }) =>
      overrideColor &&
      !limitColorOverrideToFirstChild &&
      css`
        path {
          fill: ${({ theme }) => modifyColor(overrideColor, theme)};
        }
      `}
    height: ${({ size }) => (size ? rem(size) : rem(16))};
    width: ${({ size }) => (size ? rem(size) : rem(16))};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.35;
    `}
`;

const ArrowLeftSVG = styled(ArrowSVG)`
  transform: rotate(90deg);
`;

const ArrowUpSVG = styled(ArrowSVG)`
  transform: rotate(180deg);
`;

const ArrowRightSVG = styled(ArrowSVG)`
  transform: rotate(270deg);
`;

export const Icon = ({
  name,
  url,
  size = 16,
  className,
  marginLeft,
  marginRight,
  disableInvert,
  reverseInvert,
  pointer,
  overrideColor,
  disabled = false,
  onClick,
  onKeyDown,
  onMouseEnter,
  iconRef,
  tabIndex,
  ariaLabel,
  dataTestId,
}: IconProps) => {
  const getIcon = () => {
    switch (name) {
      case 'account':
        return <AccountSVG />;
      case 'aiven':
        return <AivenSVG />;
      case 'amqp':
        return <AMQPSVG />;
      case 'api-tokens':
        return <ApiTokensSVG />;
      case 'app-criteria':
        return <AppCriteriaSVG />;
      case 'applications':
        return <ApplicationsSVG />;
      case 'arrow-down':
        return <ArrowSVG />;
      case 'arrow-left':
        return <ArrowLeftSVG />;
      case 'arrow-right':
        return <ArrowRightSVG />;
      case 'arrow-up':
        return <ArrowUpSVG />;
      case 'aws':
        return <AWSSVG />;
      case 'amazon':
        return <AmazonSVG />;
      case 'azure':
        return <AzureSVG />;
      case 'azure-sbs':
        return <AzureSbsSVG />;
      case 'base-env':
        return <BaseEnvSVG />;
      case 'bitbucket':
        return <BitbucketSVG />;
      case 'cassandra':
        return <CassandraSVG />;
      case 'checkmark':
        return <CheckMarkSVG />;
      case 'circle-ci':
        return <CircleCISVG />;
      case 'clock':
        return <ClockSVG />;
      case 'close':
        return <CloseSVG />;
      case 'cloud-accounts':
        return <CloudAccountsSVG />;
      case 'cloudflare':
        return <CloudflareSVG />;
      case 'container-images':
        return <ContainerImagesSVG />;
      case 'comments':
        return <CommentsSVG />;
      case 'cookie':
        return <CookieSVG />;
      case 'copy':
        return <CopySVG />;
      case 'cross':
        return <CrossSVG />;
      case 'default-workload':
        return <BaseSVG />;
      case 'delete':
        return <DeleteSVG />;
      case 'dns':
        return <DNSSVG />;
      case 'docs':
        return <DocsSVG />;
      case 'documents':
        return <DocumentsSVG />;
      case 'double-right-arrow':
        return <DoubleRightArrowSVG />;
      case 'edit':
        return <EditSVG />;
      case 'elasticsearch':
        return <ElasticSearchSVG />;
      case 'emptyDir':
        return <EmptyDirSVG />;
      case 'env-criteria':
        return <EnvCriteriaSVG />;
      case 'environment-types':
        return <EnvironmentTypesSVG />;
      case 'environments':
        return <EnvironmentsSVG />;
      case 'gcp':
        return <GCPSVG />;
      case 'github':
        return <GitHubSVG />;
      case 'google':
        return <GoogleSVG />;
      case 'happy':
        return <HappySVG />;
      case 'hide-menu':
        return <HideMenuSVG />;
      case 'history':
        return <HistorySVG />;
      case 'horizontal-pod-autoscaler':
        return <HpaSVG />;
      case 'humanitec':
        return <HumanitecSVG />;
      case 'info':
        return <InfoSVG />;
      case 'ingress':
        return <IngressSVG />;
      case 'k8s-namespace':
        return <NamespaceSVG />;
      case 'kubernetes':
      case 'k8s-cluster':
        return <KubernetesSVG />;
      case 'link':
        return <LinkSVG />;
      case 'lock':
        return <LockSVG />;
      case 'logging':
        return <LoggingSVG />;
      case 'logout':
        return <LogoutSVG />;
      case 'mariadb':
        return <MariaDBSVG />;
      case 'mongodb':
        return <MongoDBSVG />;
      case 'mysql':
        return <MySQLSVG />;
      case 'org-members':
        return <OrgMembers />;
      case 'organization':
        return <OrganizationSVG />;
      case 'padlock':
        return <PadlockSVG />;
      case 'pause':
        return <PauseSVG />;
      case 'pause-in-circle':
        return <PauseInCircleSVG />;
      case 'pipeline-runs':
        return <PipelineRunsSVG />;
      case 'pin':
        return <PinSVG />;
      case 'play':
        return <PlaySVG />;
      case 'plus':
        return <PlusSVG />;
      case 'postgres':
        return <PostgresSVG />;
      case 'profile':
        return <ProfileSVG />;
      case 'pulse':
        return <PulseSVG />;
      case 'rabbitmq':
        return <RabbitMqSVG />;
      case 'redis':
        return <RedisSVG />;
      case 'registries':
        return <RegistriesSVG />;
      case 'registry':
        return <RegistrySVG />;
      case 'remove':
        return <RemoveSVG />;
      case 'resource-criteria':
        return <ResourceCriteriaSVG />;
      case 'resources':
        return <ResourcesSVG />;
      case 's3':
        return <S3SVG />;
      case 'search':
        return <SearchSVG />;
      case 'shortcuts':
        return <ShortcutsSVG />;
      case 'skull':
        return <SkullSVG />;
      case 'sso':
        return <SSOSVG />;
      case 'star':
        return <StarSVG />;
      case 'tag':
        return <TagSVG />;
      case 'three-dots':
        return <ThreeDotsSVG />;
      case 'tls-cert':
        return <TLSCertSVG />;
      case 'variables':
        return <VariablesSVG />;
      case 'volume':
        return <VolumeSVG />;
      case 'warning':
        return <WarningSVG />;
      case 'workload':
        return <WorkloadSVG />;
      case 'generic-resource':
        return <GenericResourceSVG />;
      case 'person':
        return <PersonSVG />;
      case 'robot':
        return <RobotSVG />;
      case 'filter':
        return <FilterSVG />;
      case 'question':
        return <QuestionInCircleSVG />;
      case 'queued':
        return <Queued />;
      default:
        return <div />;
    }
  };

  return (
    <IconWrapper
      data-testid={dataTestId || `icon-${name}`}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      disabled={disabled}
      reverseInvert={reverseInvert}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      size={size}
      className={`${className} icon-wrapper`}
      marginLeft={marginLeft}
      marginRight={marginRight}
      disableInvert={disableInvert}
      pointer={pointer}
      overrideColor={overrideColor}
      limitColorOverrideToFirstChild={name === 'arrow-right'}
      onKeyDown={onKeyDown}
      ref={iconRef}>
      {url ? <SVGImage size={size} src={url.src} alt={url.alt} /> : getIcon()}
    </IconWrapper>
  );
};

export default Icon;
