import { Button, SearchInput } from '@humanitec/ui-components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import useResourceDriversQuery from '@src/hooks/react-query/resources/queries/useResourceDriversQuery';
import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';
import { useDecision } from '@src/hooks/useDecision';
import { ResourceDefinition } from '@src/models/resources';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { units } from '@src/styles/variables';

import AddResourceDefinitionModal from '../../components/modals/AddResourceDefinitionModal/AddResourceDefinitionModal';
import ResourcesTable from '../../components/ResourcesTable/ResourcesTable';

const Container = styled(PageContainer)`
  flex-direction: column;
  overflow: auto;
  margin-bottom: ${units.margin.lg};
`;

const AddResourcesWrapper = styled.div`
  display: flex;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${units.padding.xxl};
`;

export const ResourceDefinitions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // Component state
  const [filteredResourceDefinitions, setFilteredResourceDefinitions] = useState<
    ResourceDefinition[]
  >([]);

  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('query')?.toLowerCase() || ''
  );
  const [openAddResourceDefinitionModal, setOpenAddResourceDefinitionModal] = useState(false);

  // Optimizely
  const [resourceClassCatalogDecision] = useDecision('resource-class-catalog');

  // react query
  const { data: resourceDefinitions } = useResourceDefinitionsQuery();
  const { data: resourceTypes } = useResourceTypesQuery();
  const { data: resourceDrivers } = useResourceDriversQuery();

  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  useEffect(() => {
    const filterBasedOnSearchValue = searchValue
      ? resourceDefinitions?.filter(
          (r) =>
            r.name.toLowerCase()?.includes(searchValue) ||
            r.type.toLowerCase()?.includes(searchValue) ||
            r.id.toLowerCase()?.includes(searchValue) ||
            r.driver_type.toLowerCase().includes(searchValue) ||
            r.driver_account?.toLowerCase().includes(searchValue)
        )
      : resourceDefinitions;

    setFilteredResourceDefinitions(
      filterBasedOnSearchValue?.sort((a, b) =>
        a.is_default && b.is_default ? 0 : b.is_default ? -1 : 1
      ) || []
    );
  }, [searchValue, resourceDefinitions]);

  const filterResources = useCallback(
    (value: string) => {
      if (value) {
        searchParams.set('query', value);
      } else {
        searchParams.delete('query');
      }
      setSearchParams(searchParams);
      setSearchValue(value.toLowerCase());
    },
    [searchParams, setSearchParams]
  );

  const filterButton = (
    <SearchInput
      defaultValue={searchValue}
      name={'filter-resources'}
      placeholder={resourcesTranslations.RESOURCES_SEARCH_PLACEHOLDER}
      onChange={filterResources}
      debounceEvent
    />
  );

  const content = (
    <>
      {!resourceClassCatalogDecision.enabled && (
        <PageHeader showPageContext={false} rightContent={filterButton} />
      )}
      <AddResourcesWrapper>
        <Button
          variant={'primary'}
          iconLeft={'plus'}
          onClick={() => setOpenAddResourceDefinitionModal(true)}>
          {resourcesTranslations.ADD_RESOURCE_DEFINITION}
        </Button>
        {resourceClassCatalogDecision.enabled && filterButton}
      </AddResourcesWrapper>

      <ResourcesTable resourceDefinitions={filteredResourceDefinitions} />
      {openAddResourceDefinitionModal && resourceDrivers && resourceTypes && (
        <AddResourceDefinitionModal
          openState={[openAddResourceDefinitionModal, setOpenAddResourceDefinitionModal]}
        />
      )}
    </>
  );

  return resourceClassCatalogDecision.enabled ? (
    <div>{content}</div>
  ) : (
    <Container>{content}</Container>
  );
};
