import { Icon } from '@humanitec/ui-components';
import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import { useMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import useBreadcrumbs, { BreadcrumbItem } from '@src/hooks/useBreadcrumbs';
import { DeploymentObject } from '@src/models/deployment-object';
import { Environment } from '@src/models/environment';
import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

import { CreateDraftDeltaButton } from './components/CreateDraftDeltaButton';
import RenameModal from './components/RenameModal';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${units.padding.xxxl} 0 ${units.padding.xxl} 0;
`;

const Title = styled.h2`
  margin: 0;
  overflow-wrap: anywhere;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Subtitles = styled.div`
  display: flex;
  column-gap: ${units.padding.xl};
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
  padding-top: ${units.padding.md};
`;

const HeaderWrapper = styled.div<{ $hasRightContent: boolean }>`
  ${({ $hasRightContent }) => $hasRightContent && `max-width: 80%`};
`;

export interface PageHeaderProps {
  rightContent?: ReactNode;
  renameTitle?: {
    submitCallback: (newName: string) => void;
    modalTitle: string;
    /** Pass true to make the default value empty. e.g. When we have 'Untitled draft' as the header, we don't want that to prefill the input */
    defaultValueEmpty?: boolean;
  };
  customHeading?: string | ReactNode;
  subtitles?: (ReactElement | string)[];
  statusMessage?: ReactNode;
  /** Show page context above the title */
  showPageContext?: boolean;
  isEnvironmentPaused?: boolean;
  lastDeployment?: DeploymentObject;
  environment?: Environment;
}

const PageHeader = ({
  subtitles,
  rightContent,
  customHeading,
  renameTitle,
  statusMessage,
  showPageContext,
  isEnvironmentPaused,
}: PageHeaderProps) => {
  const crumbs = useBreadcrumbs();
  const lastCrumb: BreadcrumbItem | undefined = useMemo(() => crumbs[crumbs.length - 1], [crumbs]);

  const header = lastCrumb?.labelAsTitle && lastCrumb?.label ? lastCrumb.label : lastCrumb?.name;

  const [editing, setEditing] = useState(false);

  const deploysMatch = useMatch('/orgs/:orgId/apps/:appId/envs/:envId/deploys/*');
  const statusMatch = useMatch('/orgs/:orgId/apps/:appId/envs/:envId/status');

  return (
    <>
      <Wrapper>
        <HeaderWrapper $hasRightContent={Boolean(rightContent)}>
          {showPageContext && lastCrumb?.label && (
            <Subtitles className={'mb-sm'}>{lastCrumb.label}</Subtitles>
          )}
          <TitleWrapper>
            <Title>{customHeading || header}</Title>
            {renameTitle && (
              <Icon
                marginLeft={'md'}
                name={'edit'}
                onClick={() => setEditing(true)}
                ariaLabel={'Edit'}
                onKeyDown={(e) => {
                  if (e.keyCode === KeyBindings.ENTER) {
                    setEditing(true);
                  }
                }}
                pointer
                tabIndex={0}
              />
            )}
            <span className={'ml-md'}>{statusMessage}</span>
          </TitleWrapper>

          <Subtitles>
            {subtitles?.map((subtitle, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={i}>{subtitle}</span>
            ))}
          </Subtitles>
        </HeaderWrapper>
        <Content>
          {(deploysMatch || statusMatch) && (
            <CreateDraftDeltaButton
              className={'mr-sm'}
              buttonVariant={isEnvironmentPaused ? 'secondary' : 'primary'}
            />
          )}
          {rightContent}
        </Content>
      </Wrapper>
      {renameTitle && (
        <RenameModal
          openState={[editing, setEditing]}
          renameTitle={renameTitle}
          existingName={header}
        />
      )}
    </>
  );
};

export default PageHeader;
