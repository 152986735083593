import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ReadMore = () => {
  // i18n
  const { t } = useTranslation('orgMembers');

  return (
    <span>
      <span>{t('YOU_CAN')}</span>
      <Link
        target={'_blank'}
        to={'https://developer.humanitec.com/platform-orchestrator/security/rbac/#application-roles'}>
        {t('READ_MORE')}
      </Link>
      .
    </span>
  );
};

export default ReadMore;
