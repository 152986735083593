import {
  Button,
  Checkbox,
  DropdownItem,
  ExpandableCard,
  InfoPopup,
  WalDropdownMenu,
  WalInput,
} from '@humanitec/ui-components';
import { t } from 'i18next';
import { MouseEvent, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';
import { useDecision } from '@src/hooks/useDecision';
import { CoProvisionResourceFields, ResourceClass } from '@src/models/resources';
import { units } from '@src/styles/variables';

interface CoProvisionResourceCardProps {
  coprovisionResourceFields: CoProvisionResourceFields;
  onDeleteButtonClick: () => void;
  index: number;
  id: string;
  error?: boolean;
  viewMode?: boolean;
  resourceClasses?: ResourceClass[];
}

const COPROVISIONRESOURCES = 'coprovisionResources';

const FlexSection = styled.div`
  display: flex;
  align-items: center;
`;
const CheckboxInput = styled(Checkbox)`
  label {
    color: ${({ theme }) => theme.color.textTranslucent};
    font-size: ${units.fontSize.sm};
  }
`;

const HeaderContent = styled.div<{ hasDescriptor: boolean }>`
  display: flex;
  align-items: center;
  ${({ hasDescriptor }) =>
    hasDescriptor &&
    css`
      justify-content: space-between;
    `}
  ${({ hasDescriptor }) =>
    !hasDescriptor &&
    css`
      justify-content: end;
    `}
`;
/*
 * This contains each individual ExpandableCard containing the details of a provisioned resource
 */
const CoProvisionResourceCard = ({
  index,
  coprovisionResourceFields,
  onDeleteButtonClick,
  error,
  viewMode,
  resourceClasses,
  id,
}: CoProvisionResourceCardProps) => {
  // i18n
  const translations = t('ACCOUNT_SETTINGS').RESOURCES.COPROVISION;
  // React Query
  const { data: resourceTypes = [] } = useResourceTypesQuery();
  // Form methods
  const { watch, setValue } = useFormContext();

  const RESOURCE_CLASS_FORM_NAME = `${COPROVISIONRESOURCES}.${index}.resource_class`;

  const resourceType = watch(`${COPROVISIONRESOURCES}.${index}.resource_type`);
  const resourceId = watch(`${COPROVISIONRESOURCES}.${index}.resource_id`);
  const resourceClass = watch(RESOURCE_CLASS_FORM_NAME);

  // Optimizely
  const [isResourceClassesDropdownDecision] = useDecision('resource-class-dropdown');

  const typeResourceClasses = resourceClasses?.filter((c) =>
    resourceType ? c.resource_type === resourceType : true
  );

  const availableResourceClassesDropdownItems: DropdownItem<string>[] = useMemo(
    () => [
      {
        id: 'default',
        label: 'default',
        value: 'default',
        hideFromList: Boolean(typeResourceClasses?.find((c) => c.id.toLowerCase() === 'default')),
        description: translations.DEFAULT_RESOURCE_CLASS_DESCRIPTION,
      },
      ...(typeResourceClasses
        ?.filter((c) => c.resource_type === resourceType)
        .map((c) => ({
          id: c.id,
          label: c.id,
          value: c.id,
          description: c.description,
        })) || []),
    ],
    [resourceType, typeResourceClasses, translations.DEFAULT_RESOURCE_CLASS_DESCRIPTION]
  );

  useEffect(() => {
    setValue(
      `${COPROVISIONRESOURCES}.${index}.resource_descriptor`,
      generateDescriptor(resourceType, resourceId, resourceClass)
    );
  }, [resourceType, resourceId, resourceClass, index, setValue]);

  const handleDelete = (e: MouseEvent) => {
    e.stopPropagation();
    onDeleteButtonClick();
  };

  const generateDescriptor = (type: string, rId: string, rClass: string) => {
    return `${type}${rClass ? `.${rClass}` : ''}${rId ? `#${rId}` : ''}`;
  }; // the descriptor is generated based on the user input for resource type an resource id

  const descriptor = generateDescriptor(resourceType, resourceId, resourceClass);

  return (
    <ExpandableCard
      id={id}
      error={error}
      className={'mb-sm'}
      headerContent={
        <HeaderContent hasDescriptor={!!descriptor}>
          {descriptor || ''}{' '}
          {!viewMode && (
            <Button
              variant={'secondary'}
              iconLeft={'delete'}
              ariaLabel={translations.REMOVE_SECTION_ARIA_LABEL}
              size={'small'}
              onClick={handleDelete}
            />
          )}
        </HeaderContent>
      }
      content={
        <>
          <WalDropdownMenu
            fullWidth
            name={`${COPROVISIONRESOURCES}.${index}.resource_type`}
            label={translations.RESOURCE_TYPE}
            onItemClick={() => {
              setValue(RESOURCE_CLASS_FORM_NAME, 'default');
            }}
            items={resourceTypes.map((env) => ({
              id: env.type,
              label: env.name,
              value: env.type,
            }))}
            disabled={viewMode}
            required
            defaultText={translations.SELECT_A_RESOURCE_TYPE}
            defaultValue={coprovisionResourceFields.resource_type}
          />
          {isResourceClassesDropdownDecision.enabled ? (
            <WalDropdownMenu
              className={'mt-md mb-lg'}
              items={availableResourceClassesDropdownItems}
              name={RESOURCE_CLASS_FORM_NAME}
              label={translations.RESOURCE_CLASS}
              disabled={availableResourceClassesDropdownItems.length === 1 || viewMode}
              defaultValue={coprovisionResourceFields.resource_class}
              menuSize={'parent'}
              fullWidth
            />
          ) : (
            <WalInput
              className={'mt-md mb-lg'}
              name={RESOURCE_CLASS_FORM_NAME}
              label={translations.RESOURCE_CLASS}
              labelAbove
              defaultValue={coprovisionResourceFields.resource_class}
              standardValidation={[{ type: 'id' }]}
              viewMode={viewMode}
            />
          )}
          <WalInput
            className={'mt-md mb-lg'}
            name={`${COPROVISIONRESOURCES}.${index}.resource_id`}
            label={translations.RESOURCE_ID}
            labelAbove
            standardValidation={[{ type: 'resourceId' }]}
            defaultValue={coprovisionResourceFields.resource_id}
            viewMode={viewMode}
          />
          <FlexSection>
            <FlexSection className={'mr-lg'}>
              <CheckboxInput
                name={`coprovisionResources.${index}.match_dependents`}
                label={translations.MATCH_DEPENDENTS}
                readonly={viewMode}
                defaultChecked={coprovisionResourceFields.match_dependents}
              />
              <InfoPopup
                position={'bottom'}
                text={translations.MATCH_DEPENDANT_TOOLTIP}
                overrideColor={'text'}
              />
            </FlexSection>
            <FlexSection>
              <CheckboxInput
                name={`${COPROVISIONRESOURCES}.${index}.is_dependent`}
                label={translations.IS_DEPENDENT}
                readonly={viewMode}
                defaultChecked={coprovisionResourceFields.is_dependent}
              />
              <InfoPopup
                position={'bottom'}
                text={translations.IS_DEPENDENT_TOOLTIP}
                overrideColor={'text'}
              />
            </FlexSection>
          </FlexSection>
        </>
      }
      cardStyle={'base'}
      expandedByDefault
    />
  );
};

export default CoProvisionResourceCard;
