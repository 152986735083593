import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useMatch, useParams } from 'react-router-dom';

import { DeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentQuery from '@src/hooks/react-query/environments/queries/useDeploymentQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import { useDeploymentDeltasStore } from '@src/hooks/zustand/useDeploymentDeltasStore';
import { useDeploymentSetStore } from '@src/hooks/zustand/useDeploymentSetStore';
import { useEnvironmentCloneStore } from '@src/hooks/zustand/useEnvironmentCloneStore';
import { MatchParams } from '@src/models/routing';
import { checkDraftModeFromURL } from '@src/utilities/navigation';

const DeploymentOrDeltaContextWrapper = ({ children }: { children: ReactNode }) => {
  // Router hooks
  const location = useLocation();
  const { orgId, appId, envId, deltaId, deployId } = useParams<keyof MatchParams>() as MatchParams;

  // Component state
  const [onLatestDeployment, setOnLatestDeployment] = useState<boolean>(false);
  const [onRunningDeployment, setOnRunningDeployment] = useState<boolean>(false);
  const [onCloneDeployment, setOnCloneDeployment] = useState<boolean>(false);
  const [draftModeActive, setDraftModeActive] = useState<boolean>(true);

  const statusTabMatch = useMatch('/orgs/:orgId/apps/:appId/envs/:envId/status');

  const { setCurrentDeploymentSetId } = useDeploymentSetStore();

  // React Query
  const { data: deployment } = useDeploymentQuery();
  const { data: environment } = useEnvironmentQuery();

  // Zustand
  const { setLastVisitedDelta } = useDeploymentDeltasStore();
  const { getEnvironmentClone } = useEnvironmentCloneStore();
  const environmentClone = getEnvironmentClone(appId, envId);

  /**
   * Sets the current deployment Set Id.
   */
  useEffect(() => {
    let currentSetId;
    if (environmentClone && deployId === 'clone') {
      currentSetId = environmentClone?.set_id;
    }
    // If there is a displayedDeployment. i.e. there is 'deploymentId' in the URL, save the ID of that set.
    else if (deployment?.id) {
      currentSetId = deployment?.set_id;
    }
    // If there is 'deploymentId' in the URL, this means we are in draft mode. Take the deployment Set Id from the last deployment in the environment.
    else if (environment?.last_deploy?.set_id) {
      currentSetId = environment?.last_deploy.set_id;
      // Else set the id to 'from_deploy.set_id'. if the set is cloned from another environment
    } else if (environment?.from_deploy?.set_id) {
      currentSetId = environment?.from_deploy?.set_id;
    } else {
      // if the app is new and never deployed
      currentSetId = '';
    }
    setCurrentDeploymentSetId(currentSetId);
  }, [
    environment,
    appId,
    orgId,
    environmentClone,
    deployId,
    setCurrentDeploymentSetId,
    deployment,
  ]);

  useEffect(() => {
    setOnLatestDeployment(
      Boolean(deployId && deployId === environment?.last_deploy?.id) || Boolean(statusTabMatch)
    );
  }, [deployId, environment?.last_deploy?.id, statusTabMatch]);

  useEffect(() => {
    setOnRunningDeployment(
      Boolean(onLatestDeployment && environment?.last_deploy?.status !== 'in progress')
    );
  }, [environment, onLatestDeployment]);

  useEffect(() => {
    setOnCloneDeployment(deployId === 'clone');
  }, [environment, deployId]);

  useEffect(() => {
    setDraftModeActive(checkDraftModeFromURL(location.pathname));
  }, [location.pathname]);

  /**
   * setEnvironmentLastVisitedDelta
   */
  useEffect(() => {
    // if there is a delta in the url set it as the active delta
    if (deltaId) {
      setLastVisitedDelta(appId, envId, deltaId);
    }
  }, [orgId, appId, envId, deltaId, setLastVisitedDelta]);

  return (
    <DeploymentOrDeltaContext.Provider
      value={{
        onRunningDeployment,
        onLatestDeployment,
        onCloneDeployment,
        draftModeActive,
      }}>
      {children}
    </DeploymentOrDeltaContext.Provider>
  );
};

export default DeploymentOrDeltaContextWrapper;
