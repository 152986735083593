import { Icon, IconNames, iconNamesArray, IconSizes } from '@humanitec/ui-components';

import { ResourceTypes } from '@src/models/resources';

interface ResourceIconProps {
  type: ResourceTypes;
  isDefaultResource?: boolean;
  hasMargin?: boolean;
  size?: IconSizes;
}
const ResourceIcon = ({ type, isDefaultResource, hasMargin, size }: ResourceIconProps) => {
  return (
    <Icon
      marginRight={hasMargin ? 'md' : undefined}
      size={size}
      name={
        isDefaultResource
          ? 'humanitec'
          : type.includes('k8s-cluster')
            ? 'kubernetes'
            : type?.includes('aws') || type === 'sqs'
              ? 'amazon'
              : iconNamesArray.includes(type as IconNames)
                ? (type as IconNames)
                : 'generic-resource'
      }
    />
  );
};

export default ResourceIcon;
