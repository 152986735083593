import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { KeyValue } from '@src/models/general';
import { cl, st } from '@src/styles/global-styles';
import { KeyBindings } from '@src/types/key-bindings';
import { useWalhallForm } from '@src/utilities/form';

import { Button } from '../../base/Button/Button';
import { WalInput } from '../../base/Input/Input';
import { WalLabel } from '../../base/Label';
import SecretValue from '../../base/SecretValue/SecretValue';
import { Toggle } from '../../base/Toggle/Toggle';
import AutoCompleteInput, { AutoCompleteOptions } from '../AutoCompleteInput/AutoCompleteInput';

export interface VariablesViewAddEditProps {
  /* unique id */
  id?: string;
  /* key value object */
  keyValue?: KeyValue;
  autoCompleteOptions?: AutoCompleteOptions;
  /* method called when the save button is clicked */
  onSave?: (formData: any) => void;
  /* shows an indicator that the Save button was pressed */
  isSaving?: boolean;
  /* method called when the cancel button is clicked */
  onCancel?: () => void;
  /* toogles whether to show the description field */
  showDescription?: boolean;
  /* toogles whether to show the secret field */
  showSecret?: boolean;
  /* disables users from editting the key field */
  disableKeyField?: boolean;
  /* disables users from editting the description field */
  disableDescriptionField?: boolean;
  /* switches to one of 3 possible mode */
  mode?: 'add' | 'edit' | 'view';
  /* list of existing keys to validate against because you can"t addduplicate variable keys */
  existingKeys?: string[];

  /* Make value a required field (ex: in case of creating override)*/
  isValueFieldRequired?: boolean;
}

const FieldInput = styled(WalInput)<{ emphasizePlaceholder?: boolean }>`
  textarea,
  input {
    ${st('my-sm')}
  }
  ${({ emphasizePlaceholder: emphasisPlaceholder, theme }) =>
    emphasisPlaceholder &&
    ` textarea::placeholder {
      color: ${theme.color.text}
    }`}
`;

const FieldAutoCompleteInput = styled(AutoCompleteInput)`
  textarea,
  input {
    ${st('my-sm')}
  }
`;

export const VariablesViewAddEdit = ({
  id,
  keyValue,
  autoCompleteOptions,
  onSave,
  isSaving = false,
  onCancel,
  showDescription,
  showSecret = false,
  disableKeyField = false,
  disableDescriptionField = false,
  mode = 'view',
  existingKeys,
  isValueFieldRequired: requiredValue = false,
}: VariablesViewAddEditProps) => {
  const [focusOnValue, setFocusOnValue] = useState(false);
  const valueInputRef = useRef<HTMLInputElement>(null);
  const keyInputRef = useRef<HTMLInputElement>(null);

  const methods = useWalhallForm();
  const {
    trigger,
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isDirty, dirtyFields },
  } = methods;
  const formValues = watch();
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  // constants
  const keyName = id ? `${id}-key` : 'key';
  const valueName = id ? `${id}-value` : 'value';
  const descriptionName = id ? `${id}-description` : 'description';
  const isSecretName = id ? `${id}-is_secret` : 'is_secret';
  const isViewMode = mode === 'view';
  const [secretFocussed, setSecretFocussed] = useState(false);

  useEffect(() => {
    // Focus on key input initially
    keyInputRef.current?.focus();
  }, []);

  useEffect(() => {
    // Set initial state of Toggle
    setValue(isSecretName, keyValue?.is_secret);
  }, [isSecretName, keyValue?.is_secret, setValue]);

  const handleConfirm = useCallback(() => {
    getValues()[id ? `${id}-value` : 'value'] = getValues()[id ? `${id}-value` : 'value'].replace(
      /\\n/g,
      '\n'
    ); // unescape newlines
    trigger().then((valid) => {
      const updatedValues = getValues();
      if (valid) {
        if (mode === 'add') {
          onSave?.(updatedValues);
          reset();
        }
        if (mode === 'edit') {
          onSave?.({
            // In case of secret value, the defaultValue is ''.We need to avoid using it during form submission unless user
            // specifically changes the value. So  logic is :if ( secret and value is not changed) , do not pass value for form submission
            ...(updatedValues[isSecretName] && !dirtyFields[valueName]
              ? {}
              : {
                  value: updatedValues[valueName],
                }),
            key: updatedValues[keyName],
            description: updatedValues[descriptionName],
            index: keyValue?.index,
            is_secret: updatedValues[isSecretName],
          });
          onCancel?.();
        }
      }
    });
  }, [
    onSave,
    trigger,
    id,
    keyValue?.index,
    dirtyFields,
    mode,
    reset,
    onCancel,
    keyName,
    valueName,
    descriptionName,
    isSecretName,
    getValues,
  ]);

  const secretIsDirty = keyValue?.is_secret && dirtyFields[valueName];
  const handleCancel = () => {
    onCancel?.();
  };

  const onKeyDownForKeyField = (event: KeyboardEvent) => {
    if (event.keyCode === KeyBindings.ENTER) {
      event?.preventDefault();
      if (autoCompleteOptions) {
        setFocusOnValue(true);
      } else {
        valueInputRef.current?.focus();
      }
    }
  };

  const onKeyDownForValueField = (event: KeyboardEvent) => {
    if (event.keyCode === KeyBindings.ENTER && !event.shiftKey) {
      trigger().then((valid) => {
        if (valid) {
          handleConfirm();
        }
      });
      keyInputRef.current?.focus();
    }
  };

  const renderValueField = autoCompleteOptions ? (
    <div>
      <FieldAutoCompleteInput
        name={valueName}
        autoCompleteOptions={autoCompleteOptions}
        labelAbove
        label={uiTranslations.VALUE_LABEL}
        placeholderText={uiTranslations.VALUE_LABEL}
        viewMode={isViewMode}
        defaultValue={keyValue?.value}
        focusOnInputState={[focusOnValue, setFocusOnValue]}
        rows={4}
      />
      {!isViewMode && (
        <div {...cl('txt-sm', 'my-sm', 'txt-translucent')}>
          {uiTranslations.TYPE_TO_ADD}{' '}
          <a
            href={'https://developer.humanitec.com/platform-orchestrator/reference/placeholders'}
            target={'_blank'}
            rel={'noreferrer'}>
            {uiTranslations.PLACEHOLDERS}
          </a>
        </div>
      )}
    </div>
  ) : keyValue?.is_secret && isViewMode ? (
    <div className={'mb-md'}>
      <WalLabel>{uiTranslations.VALUE_LABEL}</WalLabel>
      <SecretValue secretVersionId={keyValue.secret_version} />
    </div>
  ) : (
    <div>
      <FieldInput
        name={valueName}
        inputRef={valueInputRef}
        labelAbove
        rows={5}
        required={requiredValue}
        label={uiTranslations.VALUE_LABEL}
        placeholder={
          keyValue?.is_secret && !requiredValue
            ? secretFocussed || secretIsDirty
              ? ''
              : '••••••••••'
            : uiTranslations.VALUE_LABEL
        }
        viewMode={isViewMode}
        defaultValue={!keyValue?.is_secret ? keyValue?.value : ''}
        onKeyDown={onKeyDownForValueField}
        onFocus={() => setSecretFocussed(true)}
        onBlur={() => setSecretFocussed(false)}
        emphasizePlaceholder={keyValue?.is_secret && !requiredValue}
      />
    </div>
  );

  const renderSecretField =
    // show an editable toggle only in add mode
    mode === 'add' ? (
      <>
        <div {...cl('txt-sm', 'my-sm')} id={'secret-toggle'}>
          {uiTranslations.SECRET}
        </div>
        <div {...cl('flex')} role={'group'} aria-labelledby={'secret-toggle'}>
          <Toggle name={isSecretName} defaultChecked={keyValue?.is_secret} />
          {formValues[isSecretName] && (
            <span className={'txt-sm'}>{uiTranslations.THIS_VARIABLE_IS_A_SECRET}</span>
          )}
        </div>
      </>
    ) : (
      // show a disabled toggle only in edit and view mode if the value is a secret
      keyValue?.is_secret && (
        <>
          <div {...cl('txt-sm', 'my-sm')} id={'secret-toggle'}>
            {uiTranslations.SECRET}
          </div>
          <div {...cl('flex')} role={'group'} aria-labelledby={'secret-toggle'}>
            <Toggle name={isSecretName} defaultChecked={keyValue?.is_secret} disabled />
            <span className={'txt-sm'}>{uiTranslations.THIS_VARIABLE_IS_A_SECRET}</span>
          </div>
        </>
      )
    );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleConfirm)}>
        <FieldInput
          labelAbove
          name={keyName}
          inputRef={keyInputRef}
          label={uiTranslations.KEY_LABEL}
          placeholder={uiTranslations.KEY_LABEL}
          readonly={disableKeyField}
          viewMode={isViewMode}
          defaultValue={keyValue?.key}
          required
          standardValidation={[{ type: 'key' }, { type: 'existingId', ids: existingKeys || [] }]}
          onKeyDown={onKeyDownForKeyField}
          onMouseDown={(e) => {
            e.stopPropagation();
            setFocusOnValue(false);
          }}
        />
        {renderValueField}
        {showDescription && (
          <>
            <FieldInput
              name={descriptionName}
              labelAbove
              rows={5}
              label={uiTranslations.DESCRIPTION}
              placeholder={uiTranslations.DESCRIPTION}
              readonly={disableDescriptionField}
              viewMode={isViewMode}
              defaultValue={keyValue?.description}
            />
          </>
        )}
        {showSecret && !isViewMode && renderSecretField}
        {!isViewMode && (
          <div {...cl('mt-xl', 'flex')}>
            {isSaving ? (
              <Button {...cl('mr-sm')} variant={'primary'} type={'submit'} disabled loading>
                {uiTranslations.SAVING}...
              </Button>
            ) : (
              <Button {...cl('mr-sm')} variant={'primary'} type={'submit'} disabled={!isDirty}>
                {uiTranslations.SAVE}
              </Button>
            )}
            <Button variant={'secondary'} onClick={handleCancel}>
              {uiTranslations.CANCEL}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default VariablesViewAddEdit;
