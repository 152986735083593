import {
  ExpandableCard,
  Icon,
  SecretValue,
  VariablesViewAddEdit,
  VariablesViewAddEditProps,
  WalTable,
  WalTableColumn,
} from '@humanitec/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import {
  OutdatedEnvs,
  OverridenValue,
  StyledDash,
} from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/SharedAppValues/SharedAppValues';
import usePlaceholders from '@src/hooks/usePlaceholders';
import { MatchParams } from '@src/models/routing';
import { generateAppURL } from '@src/utilities/navigation';

interface SharedAppValueExpandableContentProps extends VariablesViewAddEditProps {
  overridenValues?: OverridenValue[];
  outdatedEnvs?: OutdatedEnvs;
}

const SharedAppValueExpandableContent = ({
  id,
  keyValue,
  onSave,
  onCancel,
  mode,
  isSaving,
  disableKeyField,
  existingKeys = undefined,
  overridenValues,
  outdatedEnvs,
}: SharedAppValueExpandableContentProps) => {
  const autoCompleteObj = usePlaceholders();

  // i18n
  const { t } = useTranslation();
  const settingsTranslations = t('APP_SETTINGS');

  // router
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const overridesTableColumns: WalTableColumn<OverridenValue>[] = [
    {
      label: settingsTranslations.ENVIRONMENT_NAME,
      prop: 'envId',
    },
    {
      label: settingsTranslations.OVERRIDEN_TO,
      prop: 'overriden_to',
      template: (row) =>
        row.data.is_secret ? (
          <SecretValue secretVersionId={row.data.secret_version} />
        ) : (
          <p>{row.data.value || <StyledDash>-</StyledDash>}</p>
        ),
    },
    {
      prop: 'warning',
      fixedWidth: 180,
      template: (row) =>
        outdatedEnvs?.[row.data.envId]?.outdatedValues.includes(row.data.key) && (
          <>
            <Icon name={'info'} className={'mr-md'} />
            {settingsTranslations.NOT_DEPLOYED}
          </>
        ),
    },
    {
      label: '',
      prop: 'link',
      template: (row) =>
        row.data.envId && (
          <Link to={`${generateAppURL(orgId, appId, row.data.envId)}/values-and-secrets-overrides`}>
            <Icon name={'link'} />{' '}
          </Link>
        ),
    },
  ];
  const overrides = overridenValues?.filter((value) => value.key === keyValue?.key);

  const autoCompleteOptions = {
    lookupObject: autoCompleteObj,
    dropdownMenuMaxHeight: 200,
    delimiterString: {
      start: '${',
      end: '}',
    },
  };

  return (
    <>
      <VariablesViewAddEdit
        key={keyValue?.key}
        id={id}
        keyValue={keyValue}
        onSave={onSave}
        onCancel={onCancel}
        mode={mode}
        isSaving={isSaving}
        disableKeyField={disableKeyField}
        showDescription
        showSecret
        autoCompleteOptions={autoCompleteOptions}
        existingKeys={existingKeys}
      />
      {overrides && overrides.length > 0 && mode === 'view' && (
        <ExpandableCard
          id={'overrides-section'}
          cardStyle={'transparent'}
          expandedByDefault
          headerContent={
            <span>
              {overrides.length}{' '}
              {overrides.length === 1
                ? settingsTranslations.OVERRIDE
                : settingsTranslations.OVERRIDES}
            </span>
          }
          content={
            <WalTable
              rows={overrides.map((value) => ({
                data: value,
              }))}
              columns={overridesTableColumns}
              caption={settingsTranslations.OVERRIDES_TABLE}
            />
          }
        />
      )}
    </>
  );
};

export default SharedAppValueExpandableContent;
