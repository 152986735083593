import React, { ReactNode } from 'react';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';

import Icon from '..//Icon/Icon';
import { Button } from '../Button/Button';

const Wrapper = styled.div<{ $mode: Mode; disableDefaultMargin?: boolean }>`
  display: flex;
  align-items: flex-start;
  border-left: 2px solid;
  padding-left: ${units.padding.lg};
  ${({ theme, $mode }) =>
    $mode === 'warning'
      ? css`
          border-left: 2px solid ${theme.color.yellow};
        `
      : $mode === 'alert'
        ? css`
            border-radius: 5px;
            border: 2px solid ${theme.color.alert};
            padding: ${units.padding.lg} ${units.padding.xl};
          `
        : $mode === 'success'
          ? css`
              border-left: 2px solid ${theme.color.green};
            `
          : css`
              border-left: 2px solid ${theme.color.main};
            `};
  font-size: ${units.fontSize.sm};

  ${({ disableDefaultMargin }) =>
    !disableDefaultMargin &&
    css`
      margin-bottom: ${units.margin.lg};
    `}
`;

const WarningContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ErrorNumber = styled.span<{ $mode: Mode }>`
  ${({ $mode, theme }) =>
    $mode === 'warning'
      ? css`
          color: ${theme.color.yellow};
        `
      : $mode === 'alert'
        ? css`
            color: ${theme.color.alert};
          `
        : $mode === 'success'
          ? css`
              color: ${theme.color.green};
            `
          : css`
              color: ${theme.color.main};
            `};
`;

export type Mode = 'alert' | 'warning' | 'info' | 'success';

export interface WarningSectionProps {
  children: ReactNode;
  title?: string;
  /** the mode of the error: warning or alert **/
  mode?: Mode;
  /** remove default bottom margin from the component **/
  disableDefaultMargin?: boolean;
  className?: string;
  /** if the icon should be shown **/
  showIcon?: boolean;
  actionButton?: {
    text: string;
    onClick: () => void;
    isPrimary?: boolean;
  };
  /** an error number that is displayed before the error content to identify the error*/
  errorNumber?: number;
}

const WarningSection = ({
  children,
  mode = 'warning',
  title,
  disableDefaultMargin,
  className,
  showIcon,
  actionButton,
  errorNumber,
}: WarningSectionProps) => (
  <Wrapper
    className={className}
    $mode={mode}
    disableDefaultMargin={disableDefaultMargin}
    data-testid={'warning-wrapper'}>
    {errorNumber && (
      <ErrorNumber $mode={mode} className={'txt-lg mr-md'}>
        {errorNumber}
      </ErrorNumber>
    )}
    {showIcon && (
      <Icon
        size={20}
        name={mode === 'info' ? 'info' : mode === 'success' ? 'checkmark' : 'warning'}
        overrideColor={
          mode === 'alert'
            ? 'alert'
            : mode === 'warning'
              ? 'yellow'
              : mode === 'success'
                ? 'green'
                : 'main'
        }
        marginRight={'md'}
      />
    )}
    <WarningContentWrapper>
      {title && <span className={`${mode === 'alert' ? 'txt-xl' : 'txt-lg'} mb-md`}>{title}</span>}
      {children}
      {actionButton && mode !== 'alert' && (
        <div className={'flex-row mt-md'}>
          <Button
            variant={actionButton.isPrimary ? 'primary' : 'secondary'}
            onClick={actionButton.onClick}>
            {actionButton.text}
          </Button>
        </div>
      )}
    </WarningContentWrapper>
  </Wrapper>
);

export default WarningSection;
