import { Edge, Node, NodeTypes, ReactFlowProvider } from '@xyflow/react';

import ReactFlowInternal from './ReactFlowInternal';

export interface FlowDiagramProps<
  NodeData extends Record<string, unknown> = Record<string, unknown>,
> {
  nodes: Node<NodeData>[];
  edges: Edge[];
  showControls?: boolean;
  nodeTypes?: NodeTypes | undefined;
  onNodeClick?: (node: Node<NodeData>) => void;
}
export const FlowDiagram = <T extends Record<string, unknown>>(props: FlowDiagramProps<T>) => {
  return (
    <ReactFlowProvider>
      <ReactFlowInternal {...props} />
    </ReactFlowProvider>
  );
};
