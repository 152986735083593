import { getOutdatedValues } from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/SharedAppValues/shared-app-values-utils';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import useSharedAppValuesHistoryQuery from '@src/hooks/react-query/shared-values/queries/useSharedAppValuesHistoryQuery';

const useCheckLatestValueSetDeployed = (params: { appId?: string; envId?: string }) => {
  const { appId, envId } = params;
  const { responseData: envValueSets = [], isFetched: envValueSetsFetched } =
    useSharedAppValuesHistoryQuery(true, { appId, envId });

  const { data: environment, isFetched: environmentFetched } = useEnvironmentQuery({ envId });

  return {
    valueSetDeployedIsOutdated:
      envValueSetsFetched && environmentFetched && environment
        ? getOutdatedValues(environment, envValueSets).length > 0
        : false,
    environment,
  };
};

export default useCheckLatestValueSetDeployed;
