import { Icon, WarningSection } from '@humanitec/ui-components';
import { rem } from 'polished';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { css } from 'styled-components/macro';

import SectionHeader from '@src/components/shared/SectionHeader';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import { useDecision } from '@src/hooks/useDecision';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { ResourceData } from '@src/models/environment';
import { ActiveResource } from '@src/models/resources';
import { units } from '@src/styles/variables';
import { generateResourceDefinitionUrl } from '@src/utilities/navigation';

const SectionValue = styled.div`
  display: flex;
  align-items: center;
  font-size: ${units.fontSize.base};
  margin-bottom: ${units.margin.lg};
`;

const KeyEntry = styled.div<{ level: number }>`
  height: unset;
  border-radius: ${rem(6)};
  margin: 0;
  float: left;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  word-break: break-word;
`;

const MainContent = styled(Content)`
  margin-bottom: ${units.margin.md};
`;

const ResourceDataRow = styled.div<{ level: number }>`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: max-content ${rem(10)} 1fr;
  margin-bottom: ${units.margin.md};
  ${({ level }) =>
    level &&
    css`
      margin-left: calc(${level} * ${units.margin.xl});
    `}
`;

const ResourceDataSeparator = styled.div`
  justify-self: center;
`;

interface ResourceDependencyAccordionItemDetailsProps {
  /** The associated resource coming form env/resources endpoint */
  activeResource: ActiveResource;
  provisionTime?: string;
  hideResourceId?: boolean;
}

const ResourceCardDetails = ({
  activeResource,
  provisionTime,
  hideResourceId,
}: ResourceDependencyAccordionItemDetailsProps) => {
  const [resourceClassCatalogDecision] = useDecision('resource-class-catalog');
  // i18n
  const { t } = useTranslation();
  const detailsModalTranslations = t('VIEW_MODULE').EXTERNAL_RESOURCES.DETAILS_MODAL;
  const { orgRole } = useGetUserRoles();
  const { data: resourceDefinitions, isFetched: resourceDefinitionsLoaded } =
    useResourceDefinitionsQuery();

  const isResourceDefinitionDeleted =
    resourceDefinitionsLoaded &&
    !Boolean(resourceDefinitions?.find((def) => def.id === activeResource.def_id));

  /** recursive function to display the resource data */
  const showResourceData = (
    key: string,
    value: string | number | ResourceData | null,
    level: number
  ): ReactNode => {
    return (
      <React.Fragment key={key}>
        <ResourceDataRow level={level}>
          <span data-testid={'resource_data_key'}>
            <KeyEntry level={level}>{key}</KeyEntry>
          </span>
          <ResourceDataSeparator>:</ResourceDataSeparator>
          {typeof value !== 'object' && <div>{value}</div>}
        </ResourceDataRow>
        {typeof value === 'object' && value !== null && (
          <>
            {Object.entries(value).map(([nestedKey, nestedValue]) =>
              showResourceData(nestedKey, nestedValue, level + 1)
            )}
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <MainContent data-testid={'active-resource'}>
      {activeResource.res_id && !hideResourceId && (
        <>
          <SectionHeader backgroundColor={'transparent'} sticky={false}>
            {detailsModalTranslations.RESOURCE_ID}
          </SectionHeader>
          <SectionValue>{activeResource.res_id}</SectionValue>
        </>
      )}
      {activeResource.type && (
        <>
          <SectionHeader backgroundColor={'transparent'} sticky={false}>
            {detailsModalTranslations.RESOURCE_TYPE}
          </SectionHeader>
          <SectionValue>{activeResource.type}</SectionValue>
        </>
      )}
      <SectionHeader backgroundColor={'transparent'} sticky={false}>
        {detailsModalTranslations.RESOURCE_CLASS}
      </SectionHeader>
      <SectionValue>{activeResource.class || 'default'}</SectionValue>
      {activeResource.def_id && (
        <>
          <SectionHeader backgroundColor={'transparent'} sticky={false}>
            {detailsModalTranslations.RESOURCE_DEFINITION_ID}
          </SectionHeader>
          <SectionValue>
            {activeResource.def_id}
            {orgRole === 'administrator' &&
              resourceDefinitionsLoaded &&
              !isResourceDefinitionDeleted && (
                <Link
                  to={generateResourceDefinitionUrl(
                    activeResource.org_id,
                    activeResource.def_id,
                    resourceClassCatalogDecision.enabled
                  )}
                  target={'_blank'}
                  rel={'noopener noreferrer'}>
                  <Icon name={'link'} overrideColor={'main-brighter'} marginLeft={'sm'} pointer />{' '}
                </Link>
              )}{' '}
          </SectionValue>
          {isResourceDefinitionDeleted && (
            <WarningSection>
              <p className={'mb-md'}>{detailsModalTranslations.DEFINITION_DELETED_WARNING_TITLE}</p>
              <span className={'txt-translucent'}>
                {detailsModalTranslations.DEFINITION_DELETED_WARNING_TEXT} {activeResource.def_id} (
                {activeResource.def_version_id})
              </span>
            </WarningSection>
          )}
        </>
      )}
      {provisionTime && (
        <>
          <SectionHeader backgroundColor={'transparent'} sticky={false}>
            {detailsModalTranslations.PROVISION_TIME}
          </SectionHeader>
          <SectionValue>{provisionTime}s</SectionValue>
        </>
      )}
      {Object.keys(activeResource.resource ?? {}).length > 0 && (
        <>
          <SectionHeader sticky={false} backgroundColor={'transparent'}>
            {detailsModalTranslations.VALUES}
          </SectionHeader>
          <Content>
            {Object.entries(activeResource.resource ?? {}).map(([key, value]) =>
              showResourceData(key, value, 0)
            )}
          </Content>
        </>
      )}
    </MainContent>
  );
};

export default ResourceCardDetails;
