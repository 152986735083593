import { DropdownItem, WalDropdownMenu, WalInput, WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useEnvironmentCreateMutation, {
  CreateEnvironmentVariables,
} from '@src/hooks/react-query/environments/mutations/useEnvironmentCreateMutation';
import { Environment } from '@src/models/environment';
import { EnvironmentType } from '@src/models/environment-type';
import { MatchParams } from '@src/models/routing';
import { useWalhallForm } from '@src/utilities/form';
import { generateSettingsURL } from '@src/utilities/navigation';

interface AddEnvironmentModalProps {
  state: [boolean, Dispatch<SetStateAction<boolean>>];
  environments: Environment[];
}

export const CreateEnvironmentModal = ({ state, environments }: AddEnvironmentModalProps) => {
  // Component state
  const [modalOpen, setModalOpen] = state;

  // React query
  const { data: envTypes = [] } = useEnvironmentTypesQuery();

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const viewApplicationTranslations = t('VIEW_APPLICATION');

  // Redux hooks
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const location = useLocation();

  // React Query
  const {
    mutate: createEnvironment,
    isPending: isEnvironmentCreating,
    isSuccess: isEnvironmentCreated,
    error: createEnvironmentError,
  } = useEnvironmentCreateMutation();

  const fromDeployIdOptions = useMemo(() => {
    const mappedEnvs = environments
      .filter((env) => env.from_deploy_id)
      .map((environment) => ({
        label: environment.name,
        value: environment.from_deploy_id,
        id: environment.from_deploy_id || 'none',
      }));

    return [
      ...mappedEnvs,
      { label: viewApplicationTranslations.EMPTY_ENVIRONMENT, value: 'none', id: 'none' },
    ];
  }, [environments, viewApplicationTranslations.EMPTY_ENVIRONMENT]);

  const envTypeOptions: DropdownItem<EnvironmentType>[] = useMemo(
    () => envTypes.map((envType) => ({ label: envType.id, value: envType, id: envType.id })),
    [envTypes]
  );

  // Form
  const methods = useWalhallForm({
    values: {
      envTypeId: envTypeOptions?.[0]?.id,
      envId: '',
      fromDeployId: fromDeployIdOptions?.[0]?.value ?? 'none',
    },
  });

  const { setError, formState, reset, watch } = methods;
  const { isDirty } = formState;

  const envTypeId = watch('envTypeId');
  const fromDeployId = watch('fromDeployId');

  useEffect(() => {
    if (modalOpen) {
      reset();
    }
  }, [modalOpen, reset]);

  useEffect(() => {
    if (isEnvironmentCreated) {
      setModalOpen(false);
    }
  }, [isEnvironmentCreated, setModalOpen]);

  useEffect(() => {
    if (createEnvironmentError?.response?.status === 409) {
      setError('envId', {
        type: 'manual',
        message: viewApplicationTranslations.ENVIRONMENT_ALREADY_EXISTS,
      });
    }
  }, [createEnvironmentError, setError, viewApplicationTranslations.ENVIRONMENT_ALREADY_EXISTS]);

  const createEnv = (formValue: any) => {
    const payload: CreateEnvironmentVariables = {
      orgId,
      appId,
      envName: formValue.envId,
      envType: envTypeId,
    };

    if (fromDeployId !== 'none') {
      payload.deploymentId = fromDeployId;
    }

    createEnvironment(payload);
  };

  return (
    <FormProvider {...methods}>
      <WalModal
        handleFormSubmit={createEnv}
        title={viewApplicationTranslations.ADD_ENVIRONMENT_MODAL_TITLE}
        content={
          <>
            <WalInput
              name={'envId'}
              label={viewApplicationTranslations.ID}
              required
              maxLength={20}
              standardValidation={[
                { type: 'name' },
                { type: 'existingId', ids: environments.map((e) => e.id) },
              ]}
            />
            <WalDropdownMenu
              items={envTypeOptions}
              buttonVariant={'input'}
              name={'envTypeId'}
              fullWidth
              maxHeight={250}
              label={viewApplicationTranslations.ENVIRONMENT_TYPE}
              menuSize={'parent'}
              fixedBottomRow={{
                component: <span>{viewApplicationTranslations.MANAGE_TYPES}</span>,
                link: {
                  to: generateSettingsURL(orgId, 'environment-types'),
                  state: {
                    previousPage: location.pathname,
                  },
                },
              }}
            />
            <WalDropdownMenu
              name={'fromDeployId'}
              items={fromDeployIdOptions}
              buttonVariant={'input'}
              maxHeight={250}
              fullWidth
              label={viewApplicationTranslations.CLONE_FROM}
              menuSize={'parent'}
            />
          </>
        }
        actions={{
          main: {
            props: {
              type: 'submit',
              loading: isEnvironmentCreating,
            },
            text: uiTranslations.CREATE,
          },
          cancel: {},
        }}
        openState={[modalOpen, setModalOpen]}
        disableClickOutside={isDirty}
        disableOverflow
      />
    </FormProvider>
  );
};
