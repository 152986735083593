import { TabGroup } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDecision } from '@src/hooks/useDecision';

const ViewDeploymentOrDeltaTabs = () => {
  // i18n
  const { t } = useTranslation('viewDeployment');
  const tabsTranslations = t('TABS');

  // Context
  const { onRunningDeployment, draftModeActive } = useDeploymentOrDeltaContext();
  const [resourceDependencyGraphDecision] = useDecision('resource_dependency_graph');

  return (
    <TabGroup
      options={[
        {
          value: 'workloads',
          label: tabsTranslations.WORKLOADS,
          link: { to: 'workloads' },
        },
        {
          value: 'shared-resources',
          label: tabsTranslations.SHARED_RESOURCES,
          link: { to: 'shared-resources' },
        },
        {
          value: 'graph',
          label: tabsTranslations.RESOURCE_DEPENDENCY_GRAPH,
          link: { to: 'graph' },
          hide: !resourceDependencyGraphDecision.enabled,
        },
        {
          value: 'deployed-values',
          label: tabsTranslations.DEPLOYED_VALUES,
          link: { to: 'deployed-values' },
          hide: draftModeActive || onRunningDeployment,
        },
      ]}
    />
  );
};

export default ViewDeploymentOrDeltaTabs;
